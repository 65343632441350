import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function LandlordTenants() {
    const { id } = useParams();
    const [propertyList, setPropertyList] = useState([]);
    const [shortLinkDetails, setShortLinkDetails] = useState(null)
    const [error, setError] = useState(false);
    const [listData, setListData] = useState(null)
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [loading, setLoading] = useState(false)
    const [downloadingPdf, setDownloadingPdf] = useState(false);

    const navigate = useNavigate()
    const getProperties = async () => {
        setLoading(true)
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            setShortLinkDetails(shortLinkUrl)
            if (shortStatus == 200) {
                const { data, status } = await axios.get(`${LIVEURLNEW2}landlordTenantProperty?isWeb=true`, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } });
                if (status == 200) {
                    setListData(data)
                    data?.map((item) => {
                        setPropertyList((prev) => {
                            return [...prev, { value: item?._id, label: item?.property?.building_name, details: item }]
                        });
                    })
                }
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(true)
        }
    }

    useEffect(() => {
        getProperties();
    }, [])


    const rejectTenant = async (details) => {
        try {
            const payload = {
                isDeleted: true,
                landlord_status: "Rejected",
            }
            const { status } = await axios.patch(`${LIVEURLNEW2}landlordTenantProperty/${details?._id}`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (status == 200 || status == 201) {
                setListData(prev => prev?.filter(item => item?._id != details?._id));
            }
        } catch (e) {
            toast.error("Something went wrong while rejecting tenant,Please try after sometime")
        }
    }

    const downloadReport = async (landlordTenantPropertyId) => {
        if (downloadingPdf) return;
        try {
            setDownloadingPdf(true)
            const payload = {
                landlordTenantPropertyId: landlordTenantPropertyId
            }
            const { status, data } = await axios.post(`${LIVEURLNEW2}reports/generateReport`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (status == 200 || status == 201) {
                console.log(data)

            }
            setDownloadingPdf(false)
        } catch (e) {
            setDownloadingPdf(false)
            if (e?.response?.data?.message) {
                toast.error(e?.response?.data?.message)
                return
            }
            toast.error("Something went wrong while generating report")
        }
    }

    const openWhatsApp = () => {
        window.location.href = `whatsapp://send?phone=+919505895078&text=${encodeURIComponent("Hi")}`;
    };



    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Please view the tenants</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : <ul className="app-login-form-field-group">
                                <span style={{ marginBottom: 10 }}>Please select property to view tenants based on property</span>
                                <Select placeholder="Select property" onChange={(e) => {
                                    setSelectedProperty(e?.details)
                                }} options={propertyList} />
                                <li>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="app-register-form-field">
                                                <Grid container spacing={2}>
                                                    {
                                                        (selectedProperty ? listData?.filter(newFor => newFor?.property?._id == selectedProperty?.property?._id) : listData ?? []).map((item) => {
                                                            console.log(item?.rentalAgreement?.length)
                                                            return <Grid item xs={12} lg={12} marginTop={3}>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src="/images/default-user.png" style={{ height: '75px', width: '75px', borderRadius: 50, marginRight: 15 }} />
                                                                    <div className='d-flex flex-column'>
                                                                        <span className='font-weight-bold'>{item?.tenant?.first_name} {item?.tenant?.last_name}</span>
                                                                        <span className='fs-14'>{item?.tenant?.mobile}</span>
                                                                        <span className='fs-14 text-wrap' style={{ lineBreak: 'anywhere' }}>{item?.tenant?.email}</span>
                                                                        <span className='fs-14 '>Type : {item?.tenant_type == "Prospective" ? "New" : item?.tenant_type} Tenant</span>
                                                                        <span className='fs-14' style={{ lineBreak: 'anywhere' }}>Property : {item?.property?.building_name}</span>
                                                                    </div>
                                                                </div>
                                                                {item?.tenant_type == "Prospective" && <div className='d-flex justify-content-end mt-4'>
                                                                    <button onClick={() => { downloadReport(item?._id) }} type="button" class="btn btn-success mr-3">
                                                                        {downloadingPdf ? <CircularProgress size={25} color='inherit' /> : 'Download Report'}
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        rejectTenant(item)
                                                                    }} type="button" class="btn btn-danger mr-3">
                                                                        Reject
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        navigate(`/ConfirmTenant/${id}`, { state: { details: item } })
                                                                    }} type="button" class="btn btn-success">
                                                                        Accept
                                                                    </button>
                                                                </div>}
                                                                {item?.tenant_type == "Existing" && <div className='d-flex justify-content-end mt-4'>
                                                                <button onClick={() => { downloadReport(item?._id) }} type="button" class="btn btn-success mr-3">
                                                                        {downloadingPdf ? <CircularProgress size={25} color='inherit' /> : 'Download Report'}
                                                                    </button>
                                                                    {/* {item?.rentalAgreement && item?.rentalAgreement?.length == 0 && <button onClick={() => {
                                                                        navigate(`/rentalAgreement/${id}`, { state: { details: item } })
                                                                    }} type="button" class="btn btn-success mr-3">
                                                                        Create Rental Agreement
                                                                    </button>} */}
                                                                </div>}
                                                            </Grid>
                                                        })
                                                    }
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </li>

                            </ul>
                            }
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="app-auth-button justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={openWhatsApp}

                                        >
                                            {'Back to whatsapp'}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
