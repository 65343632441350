import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SelectNew from 'react-select';
import * as yup from "yup";
import "yup-phone";
import moment from 'moment';
import { Checkbox } from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



export default function AddRwa() {

    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);
    const [rwaName, setRwaName] = useState("");
    const [agreeStatus,setAgreeStatus] = useState(false)
    const [tenantAdded, setTenantAdded] = useState(false)
    const getValidationSchema = () => {
        const PHONE_NO_REGEX = /^[0-9\- ]{8,14}$/
        return yup
            .object({
                name: yup.string().required("It is a required field"),
                pincode: yup.string().required("It is a required field"),
                email: yup.string().email().required("It is a required field"),
                mobile: yup.string().matches(PHONE_NO_REGEX, { message: "not a valid phone no", excludeEmptyString: true }).length(10),
                state: yup.string().required("It is a required field"),
                city: yup.string().required("It is a required field"),
                address: yup.string().required("It is a required field"),
                // registrationNo: yup.string().required("It is a required field"),
                doorNo: yup.string().required("It is a required field"),
                totalBlocks: yup.string().required("It is a required field"),
                totalFlats: yup.string().required("It is a required field"),
                password: yup.string()
                    .required('No password provided.')
                    .min(8, 'Password is too short - should be 8 chars minimum.')
                    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
                passwordConfirmation: yup.string()
                    .oneOf([yup.ref('password'), null], 'Passwords must match')
            })
    }

    const validationSchema = getValidationSchema();

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        localStorage.clear();
        window.onbeforeunload = function () {
            localStorage.clear();
        }
        window.onunload = () => {
            window.localStorage.clear()
        }
    }, [])


    const createRWA = async (formValues) => {
        // if(!agreeStatus){
        //     toast.error("")
        //     return
        // }
        try {
            setLoading(true)
            localStorage.clear();
            const payload = { ...formValues, mobile: `91${formValues?.mobile}` }
            const { status , data} = await axios.post(`${LIVEURLNEW2}RWA/register`, payload);
            if (status == 200 || status == 201) {
                toast.success("RWA added successfully")
                setTenantAdded(true)
                localStorage.clear();
                setLoading(false)
                navigate("/rwaLogin")
            } else {
                if(data?.message){
                    setLoading(false)
                    toast.error(data?.message)
                    return
                }
                toast.error("Something went wrong, Try again")
                setLoading(false)
            }
        } catch (e) {
            if(e?.response.data?.message){
                setLoading(false)
                toast.error(e?.response?.data?.message)
                return
            }
            setLoading(false)
        }
    }



    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            {!tenantAdded && <p>Please fill the form to create RWA</p>}
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {tenantAdded ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>RWA added successfully</span>
                            </div> : <form onSubmit={handleSubmit(createRWA)}>
                                <ul className="app-login-form-field-group">
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="RWA name"
                                                                className="app-login-form-field"
                                                                onChange={(e) => { setRwaName(e?.target?.value); setValue("name", e?.target?.value) }}
                                                            // {...register("name")}
                                                            />
                                                            {errors?.name && <span className="app-warning-content">{errors?.name?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Mobile number"
                                                                className="app-login-form-field"
                                                                {...register("mobile")}
                                                            />
                                                            {errors?.mobile && <span className="app-warning-content">{errors?.mobile?.message}</span>}

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="email address"
                                                                className="app-login-form-field"
                                                                {...register("email")}
                                                            />
                                                            {errors?.email && <span className="app-warning-content">{errors?.email?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Address"
                                                                className="app-login-form-field"
                                                                {...register("address")}
                                                            />
                                                            {errors?.address && <span className="app-warning-content">{errors?.address?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="City"
                                                                className="app-login-form-field"
                                                                {...register("city")}
                                                            />
                                                            {errors?.city && <span className="app-warning-content">{errors?.city?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="State"
                                                                className="app-login-form-field"
                                                                {...register("state")}
                                                            />
                                                            {errors?.state && <span className="app-warning-content">{errors?.state?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Pincode"
                                                                className="app-login-form-field"
                                                                {...register("pincode")}
                                                            />
                                                            {errors?.pincode && <span className="app-warning-content">{errors?.pincode?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Registration Number"
                                                                className="app-login-form-field"
                                                                {...register("registrationNo")}
                                                            />
                                                            {errors?.registrationNo && <span className="app-warning-content">{errors?.registrationNo?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Plot Number"
                                                                className="app-login-form-field"
                                                                {...register("doorNo")}
                                                            />
                                                            {errors?.doorNo && <span className="app-warning-content">{errors?.doorNo?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Total Blocks"
                                                                className="app-login-form-field"
                                                                {...register("totalBlocks")}
                                                            />
                                                            {errors?.totalBlocks && <span className="app-warning-content">{errors?.totalBlocks?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Total Flats"
                                                                className="app-login-form-field"
                                                                {...register("totalFlats")}
                                                            />
                                                            {errors?.totalFlats && <span className="app-warning-content">{errors?.totalFlats?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="No. of occupents"
                                                                className="app-login-form-field"
                                                                {...register("totalMembers")}
                                                            />
                                                            {/* {errors?.totalMembers && <span className="app-warning-content">{errors?.totalMembers?.message}</span>} */}
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"password"}
                                                                placeholder="Password"
                                                                className="app-login-form-field"
                                                                {...register("password")}
                                                            />
                                                            {errors?.password && <span className="app-warning-content">{errors?.password?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"password"}
                                                                placeholder="Confirm Password"
                                                                className="app-login-form-field"
                                                                {...register("passwordConfirmation")}
                                                            />
                                                            {errors?.passwordConfirmation && <span className="app-warning-content">{errors?.passwordConfirmation?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "30px" }}>
                                                        <div style={{display:'flex',alignItems:'center'}}>
                                                            <Checkbox onChange={(e) => {setAgreeStatus(e?.target?.checked)}}/>
                                                            <span style={{ fontSize: 12, marginLeft: 0, }}>We {getValues()?.name ? getValues()?.name : '_______'}  agree to partner with <b>Score10</b>, Tenant Scoring platform and use the Tenant verification service for a span of one year from the Date of {moment().format("DD MMM YYYY")}. We agree to your proposal submitted and we are happy to avail this free Service for all our community landlords who wish to verify their primary tenants.</span>
                                                        </div>
                                                    </Grid>
                                                </div>


                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-auth-button justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        disabled={!agreeStatus || loading}
                                                        style={{color:'white',background:!agreeStatus ? 'grey' : null}}
                                                    >
                                                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Submit'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
