import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registerSchema } from '../utils/validations';
import axios from 'axios';
import { BASEURL, LIVEURL } from '../utils/constant';
import { useContext } from "react";
import { UserRoleContext } from "../utils/userAuthorization";
import { toast } from 'react-toastify';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function RegisterTenant() {
    const navigate = useNavigate();
    const roleContext = useContext(UserRoleContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(registerSchema),
    });
    const onSubmit = async (formData) => {
        if (formData.password != formData?.confirm_password) {
            alert("password and confirm password should be same")
            return
        }
        const payload = {
            tenent_first_name:formData.first_name,
            tenent_last_name: formData.last_name,
            tenent_mobile_number:formData.mobile,
            tenent_email:formData?.email,
            password: formData.password,
        }
        axios.post(`${LIVEURL}tenant/register`, payload , {
            headers: {
                Authorization:"Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                toast.success("Tenant registered successfully")
                navigate(-1)
            } 
        }).catch((e) =>{
            if(e.response.status == 400){
                toast.error(e.response?.data?.message)
                return
            }
            toast.error("Something went wrong")

        });
    };
    return (
        <>
        <header>
            <div className="container">
                <div className="app-new-auth-header">
                    <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png"/>
                    <Link to="/landlord">
                        <Button className="app-home-cta" variant="contained">
                          Go Back
                        </Button>
                    </Link>
                </div>
            </div>
        </header>
        <div className="app-new-auth-page register-bg-image">
            <div className="container h-100">
                <div className="app-new-auth-page-wrapper">
                    <div className="app-new-auth-page-heading">
                        <h1>Welcome</h1>
                        <p>Create New Score10 Tenant's Account</p>
                    </div>
                    <div className="app-new-auth-page-card">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ul className="app-login-form-field-group">
                            <li>
                                <input type={'text'} placeholder="First Name" className="app-login-form-field" {...register("first_name")} />
                                {errors?.first_name && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.first_name?.message}</span>}
                            </li>
                            <li>
                                <input type={'text'} placeholder="Last Name" className="app-login-form-field" {...register("last_name")} />
                                {errors?.last_name && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.last_name?.message}</span>}
                            </li>
                            <li>
                                <input type={'text'} placeholder="Email Address" className="app-login-form-field"  {...register("email")} />
                                {errors?.email && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.email?.message}</span>}
                            </li>
                            <li>
                                <input type={'number'} min="1" placeholder="Contact number" className="app-login-form-field" {...register("mobile")} />
                                {errors?.mobile && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.mobile?.message}</span>}</li>
                            <li>
                                <input type={'password'} placeholder="Password" className="app-login-form-field" {...register("password")} />
                                {errors?.password && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.password?.message}</span>}
                            </li>
                            <li>
                                <input type={'password'} placeholder="Confirm Password" className="app-login-form-field" {...register("confirm_password")} />
                               
                            </li>
                        </ul>
                        <div className="app-auth-button">
                            <Button type="submit" variant="contained">Create Tenant's Account</Button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default RegisterTenant