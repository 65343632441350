import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Checkbox, CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function SelectAddress() {
    const { id } = useParams();
    const [shortLinkDetails, setShortLinkDetails] = useState(null);
    const location = useLocation();
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const getShortLinkDetails = async () => {
        try {
            setLoading(true)
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            setShortLinkDetails(shortLinkUrl)
            if (shortStatus == 200) {
                const { details } = location.state
                const payload = {
                    "first_name": details?.landlord?.first_name,
                    "mobile": details?.landlord?.mobile.toString(),
                    "email": details?.landlord?.email,
                    "amount": 2.00
                }
                const { data, status } = await axios.post(`${LIVEURLNEW2}paymentHistory/initiatePayment`, payload, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } })
                if (status == 200) {
                    easebuzzPayment(data?.data)
                    return
                }
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
        }

    }


    const easebuzzPayment = (data) => {
        var easebuzzCheckout = new window.EasebuzzCheckout("TNNW0YJS0B", 'prod')
        var options = {
            access_key: data,
            onResponse: (response) => {
                if (response?.status == "userCancelled") {
                    toast.error("User cancelled transaction");
                    return
                }
                if (response?.status == "dropped") {
                    toast.error("User dropped transaction");
                    return
                }
                if (response?.status == "success") {
                    updatePaymentTransaction("Success", response);
                } else {
                    updatePaymentTransaction("Failed", response);
                }
                console.log(response);
            },
            theme: "#123456"
        }
        easebuzzCheckout.initiatePayment(options);
    }


    const updatePaymentTransaction = async (status, transactionDetails) => {
        try {
            const { details } = location.state
            const payload = {
                "status": status,
                "landlordTenantProperty": details?._id,
                "property": details?.property?._id,
                "paymentType": "Rental Agreement",
                "amount": 499.00,
                "convenienceFee": 0.0,
                "gstAmount": 0.0,
                "paymentGateway": "Eazebuzz",
                "transactionDetails": transactionDetails
            }
            const { data, status: paymentStatus } = await axios.post(`${LIVEURLNEW2}paymentHistory/`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                navigate(`/PreviewRentalAgreement/${id}`, { state: { details: location?.state?.details, paymentDetails: data } })
                setLoading(false)
            }
        } catch (e) {
            setLoading(false);
        }
    }
    const openWhatsApp = () => {
        // if (selectedProperty == null) {
        //     toast.error("Please select the property");
        //     return
        // }
        window.location.href = `whatsapp://send?phone=+919505895078`;
    };

    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p className='font-weight-bold'>Address selection</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : <ul className="app-login-form-field-group" style={{ justifyContent: 'center', display: 'flex' }}>
                                <span  className='font-weight-bold' style={{ textAlign: 'center' }}>Please select your current address among below list</span>
                                <li>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="app-register-form-field">
                                                <Grid container spacing={2}>

                                                    <Grid item xs={12} lg={12}>
                                                        <div className='d-flex justify-content-start align-items-center' style={{ marginTop: 20 }}>
                                                            <div className='d-flex flex-column'>
                                                                <div className='d-flex align-items-center'>
                                                                    <Checkbox />
                                                                    <span className='font-weight-bold fs-14'>8-496,Indra reddy allwyn colony,Hyderabad,Telangana,500049</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} lg={12}>
                                                        <div className='d-flex justify-content-start align-items-center'>
                                                            <div className='d-flex flex-column'>
                                                                <div className='d-flex align-items-center'>
                                                                    <Checkbox />
                                                                    <span className='font-weight-bold fs-14'>1-101,Sangupalem koduru,pitlavaripalem,Guntur,Andra pradesh,522311</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </li>
                            </ul>}

                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="app-auth-button justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={getShortLinkDetails}
                                            style={{color:'white'}}
                                        >
                                            {loading ? <CircularProgress size={25} color='inherit' /> : 'Done'}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
