import * as React from "react"

const DownChevron = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.157}
    height={7.286}
    {...props}
  >
    <g data-name="Group 21" fill="none" stroke="#000" strokeLinecap="round">
      <path data-name="Line 4" d="m.707.707 5.872 5.872" />
      <path data-name="Line 5" d="M12.451.707 6.579 6.579" />
    </g>
  </svg>
)

export default DownChevron
