import { Routes, Route , Navigate} from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import TenantForm from "./pages/TenantForm"
import './App.css';
import { UserRoleContextConsumer } from "./utils/userAuthorization";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RegisterTenant from "./pages/RegisterTenant";
import VerificationDetails from "./pages/verificationDetails";
import AadharReturnPage from "./pages/AadharReturnPage";
import LandLordsDashboard from "./pages/landLordsDashboard";
import TenantDetails from "./pages/tenantDetails";
import LandingPage from "./pages/landingPage";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsAndCondition from "./pages/termsAndConditions";
import ForgotPassword from "./pages/auth/forgotPassword";
import SendEmail from "./pages/auth/sendEmail";
import RefundPolicy from "./pages/RefundPolicy";
import AddProperty from "./pages/addProperty";
import PropertyList from "./pages/propertyList";
import VerificationLink from "./pages/VerificationLink";
import AddTenant from "./pages/addTenant";
import LandlordProperties from "./pages/landlordProperties";
import PaymentLink from "./pages/PaymentLink";
import RentalAgreement from "./pages/RentalAgreementForm";
import PreviewRentalAgreement from "./pages/PreviewRentalAgreement";
import LandlordTenants from "./pages/landlordTenants";
import ConfirmTenant from "./pages/makeNewToExisting";
import SelectAddress from "./pages/SelectAddress";
import AddRwa from "./pages/addRWA";
import RWALogin from "./pages/rwaLogin";
import RWADashboard from "./pages/RWADashboard";
import AddLandlordTenantByRWA from "./pages/addLandlordTenantByRWA";
import SendLinkToLandlord from "./pages/sendLinkToLandlord";
import RaiseTicket from "./pages/raiseTicket";

import './global.css';
import ThemeProvider from './theme';

function App() {
  const notLoggedIn = (<>
    {/* <Route path="/" element={<Login />} /> */}
    <Route path="/" element={<LandingPage />} />
    <Route path="home" element={<LandingPage />} />
    <Route path="privacy-policy" element={<PrivacyPolicy />} />
    <Route path="refund-policy" element={<RefundPolicy />} />
    <Route path="terms-and-conditions" element={<TermsAndCondition />} />
    <Route path="login" element={<Login />} />
    <Route path="createProperty/:id" element={<AddProperty />} />
    <Route path="createTenant/:id" element={<AddTenant />} />
    <Route path="propertyList/:id" element={<PropertyList />} />
    <Route path="register" element={<Register />} />
    <Route path="forgot-password/:id" element={<ForgotPassword />} />
    <Route path="forgot-password/user/:id" element={<ForgotPassword />} />
    <Route path="sendEmail" element={<SendEmail />} />
    <Route path="verificationLink/:id/:tenant" element={<VerificationLink />} />
    <Route path="return-url" element={<AadharReturnPage />} />
    <Route path="landlordProperties/:id" element={<LandlordProperties />} />
    <Route path="rentalAgreement/:id" element={<RentalAgreement />} />
    <Route path="selectAddress/:id" element={<SelectAddress />} />
    <Route path="PaymentLink/:id" element={<PaymentLink />} />
    <Route path="helpAndSupport/:id" element={<RaiseTicket />} />
    <Route path="PreviewRentalAgreement/:id" element={<PreviewRentalAgreement />} />
    <Route path="landlordTenants/:id" element={<LandlordTenants />} />
    <Route path="ConfirmTenant/:id" element={<ConfirmTenant />} />
    <Route path="addrwa" element={<AddRwa />} />
    <Route path="rwaLogin" element={<RWALogin />} />
    <Route path="addLandlordTenantByRWA/:id" element={<AddLandlordTenantByRWA />} />
    <Route path="*" element={<Navigate to ="/" />}/>

  </>);

  const dashboardRoutes = (<>
    <Route path="tenant-form" element={<TenantForm />} />
    <Route path="verification-result" element={<VerificationDetails />} />
    <Route path="register-tenant" element={<RegisterTenant />} />
    <Route path="return-url" element={<AadharReturnPage />} />
    <Route path="landlord" element={<LandLordsDashboard />} />
    <Route path="tenant/:id" element={<TenantDetails />} />
    <Route path="createProperty/:id" element={<AddProperty />} />
    <Route path="createTenant/:id" element={<AddTenant />} />
    <Route path="propertyList/:id" element={<PropertyList />} />
    <Route path="landlordProperties/:id" element={<LandlordProperties />} />
    <Route path="rentalAgreement/:id" element={<RentalAgreement />} />
    <Route path="selectAddress/:id" element={<SelectAddress />} />
    <Route path="PaymentLink/:id" element={<PaymentLink />} />
    <Route path="PreviewRentalAgreement/:id" element={<PreviewRentalAgreement />} />
    <Route path="landlordTenants/:id" element={<LandlordTenants />} />
    <Route path="ConfirmTenant/:id" element={<ConfirmTenant />} />
    <Route path="addrwa" element={<AddRwa />} />
    <Route path="helpAndSupport/:id" element={<RaiseTicket />} />
    <Route path="rwaLogin" element={<RWALogin />} />
    <Route path="rwaDashboard" element={<RWADashboard />} />
    <Route path="sendLinkToLandlord" element={<SendLinkToLandlord />} />
    <Route path="addLandlordTenantByRWA/:id" element={<AddLandlordTenantByRWA />} />
    <Route path="verificationLink/:id/:tenant" element={<VerificationLink />} />
    <Route path="*" element={<Navigate to ="rwaDashboard" />}/>
  </>)


  return (
    <ThemeProvider>
    <UserRoleContextConsumer>
      {(value) => {
        console.log(value?.isLoggedIn || localStorage.getItem("accessToken"))
        return <>
          <Routes>
            {value?.isLoggedIn || localStorage.getItem("accessToken")
              ? dashboardRoutes
              : notLoggedIn}
          </Routes>
          <ToastContainer />
        </>
      }
      }

    </UserRoleContextConsumer>
    </ThemeProvider>
  );
}

export default App;
