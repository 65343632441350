import * as React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { emailSchema, forgotPasswordSchema } from '../../utils/validations';
import axios from 'axios';
import { LIVEURL } from '../../utils/constant';
import { useContext } from "react";
import { UserRoleContext } from "../../utils/userAuthorization";
import { toast } from 'react-toastify';

function SendEmail() {
    const navigate = useNavigate();
    const roleContext = useContext(UserRoleContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(emailSchema),
    });
    const onSubmit = async (formData) => {
        const payload = {
         email: formData.email
        }
        axios.post(localStorage.getItem("isUser") ? `${LIVEURL}tenant/generate-forgot-password-email` :`${LIVEURL}user/generate-forgot-password-email`, payload).then((res) => {
            if (res.status === 200) {
                navigate("/login")
                toast.success(res?.data?.message)
            } 
        }).catch((e) =>{
            if(e.response.status == 400){
                toast.error(e.response?.data?.message)
            }
            else if(e.response.status == 404){
                toast.error(e.response?.data?.message)
            }
            else{
                toast.error("something went wrong");
            }
        });
    };
    return (
        <>
         <header>
            <div className="container">
                <div className="app-new-auth-header">
                    <img onClick={() => {
                        navigate("/")
                    }} className="app-new-auth-header-icon" src="/images/landing-page/logo3.png"/>
                    <Link to="/login">
                        <Button>
                            <img src="/images/sign-in-icon.svg"/>
                            Forgot Password
                        </Button>
                    </Link>
                </div>
            </div>
        </header>
        <div className="app-new-auth-page register-bg-image">
            <div className="container h-100">
                <div className="app-new-auth-page-wrapper">
                    <div className="app-new-auth-page-heading">
                        <h1>Welcome</h1>
                        <p>Forgot password</p>
                    </div>
                    <div className="app-new-auth-page-card">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <ul className="app-login-form-field-group">
                            <li>
                            <input type={'text'} placeholder="Enter Your Email ID" className="app-login-form-field" {...register("email")} />
                            {errors?.email && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.email?.message}</span>}
                            </li>
                        </ul>
                        <div className="app-auth-button">
                            <Button type="submit" variant="contained">Generate new password</Button>
                        </div>
                        <p className="app-login-form-button-alt">I already have an account <Link to="/login">Login</Link></p>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SendEmail;