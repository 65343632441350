import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import DownChevron from "../icons/DownChevron";
import Button from "@mui/material/Button";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LIVEURL } from "../utils/constant";
import axios from "axios";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function FamilyMember({ isEdit, getTenetVerifiedDetails, handleClose, setFamily_members_data, tenant_details }) {
    const [personName, setPersonName] = React.useState([]);
    const [gender, setgender] = React.useState([]);
    const [loading, setLoading] = React.useState(false)
    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );

        if (value == "PAN Card (PAN Card Number)") {

        } else if (value == "Driving License") {

        } else if (value == "Passport (Passport Application File Number)") {

        } else if (value == "Voter ID Card (Voted ID or EPIC Number)") {

        }

        //setValue("id_name", "panCard")
        console.log(value)
    };
    const handleChange2 = (event) => {
        const {
            target: { value },
        } = event;
        setgender(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );



        setValue("tenent_gender", value)
        console.log(value)
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        //  resolver: yupResolver(update_form),
    });

    const addTenent = (formData) => {
        setLoading(true)
        const data = {
            more_tenents: tenant_details.more_tenents ? [...tenant_details.more_tenents, { ...formData, "id_type": "aadhar", isVerified: false }] : [{ ...formData, "id_type": "aadhar", isVerified: false }],
        };
        axios.put(`${LIVEURL}tenant/update_more_tenants`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setFamily_members_data([tenant_details.more_tenents, { ...formData, "id_type": "aadhar" }])
                getTenetVerifiedDetails();
                handleClose();
                //  navigate("/verification-result")
            } else {
                setLoading(false)
                alert(res?.response?.data?.message);
            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                alert("Not valid Id number")
            }
            setLoading(false)
        })
    }

    const editTenent = (formData) => {
        let final_out = tenant_details.more_tenents;
        final_out[isEdit] = { ...formData };
        setLoading(true)
        const data = {
            more_tenents: final_out,
        };
        axios.put(`${LIVEURL}tenant/update_more_tenants`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setFamily_members_data([tenant_details.more_tenents, { ...formData, "id_type": "aadhar" }])
                getTenetVerifiedDetails();
                handleClose();
                //  navigate("/verification-result")
            } else {
                setLoading(false)
                alert(res?.response?.data?.message);
            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                alert("Not valid Id number")
            }
            setLoading(false)
        })
    }

    const onSubmit = async (formData) => {
        console.log(formData)
        if (isEdit == null) {
            addTenent(formData);
        } else {
            editTenent(formData);
        }

    };
    React.useEffect(() => {
    
        if (isEdit != null) {
            setValue("id_number", tenant_details.more_tenents[isEdit]?.id_number)
            setValue("id_type", tenant_details.more_tenents[isEdit]?.id_type)
            setValue("isVerified", tenant_details.more_tenents[isEdit]?.isVerified)
            setValue("tenent_dob", tenant_details.more_tenents[isEdit]?.tenent_dob)
            setValue("tenent_father_first_name", tenant_details.more_tenents[isEdit]?.tenent_father_first_name)
            setValue("relation_with_primary_tenant", tenant_details.more_tenents[isEdit]?.relation_with_primary_tenant)
            setValue("tenent_father_last_name", tenant_details.more_tenents[isEdit]?.tenent_father_last_name)
            setValue("tenent_first_name", tenant_details.more_tenents[isEdit]?.tenent_first_name)
            setValue("tenent_last_name", tenant_details.more_tenents[isEdit]?.tenent_last_name)
            setValue("tenent_gender", tenant_details.more_tenents[isEdit]?.tenent_gender)
         
        }
    }, [isEdit])


    return (
        <div className="family-tenent app-login-form-field-group family-tenant-modal">
            <form onSubmit={handleSubmit(onSubmit)}>
                <li className="app-register-form-field-group-item">
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={12}>

                            <div className="app-register-form-field">
                                
                                <div className="">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <input
                                                type={"text"}
                                                placeholder="Tenant First Name"
                                                className="app-login-form-field"
                                                {...register("tenent_first_name")}

                                            />
                                            {errors?.tenent_first_name && (
                                                <span style={{ color: "red" }}>
                                                    {errors?.tenent_first_name?.message}
                                                </span>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <input
                                                type={"text"}
                                                placeholder="Tenant Last Name"
                                                className="app-login-form-field"
                                                {...register("tenent_last_name")}

                                            />
                                            {errors?.tenent_last_name && (
                                                <span style={{ color: "red" }}>
                                                    {errors?.tenent_last_name?.message}
                                                </span>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div className="app-register-form-field">
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={12} lg={6}>
                                <div className="app-register-form-field">

                                    <FormControl className="w-100" sx={{}}>
                                        <Select
                                            displayEmpty
                                            className="app-custom-select"
                                            value={gender}
                                            onChange={handleChange2}
                                            IconComponent={DownChevron}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <span className="app-custom-select-placeholder">
                                                            Select Gender
                                                        </span>
                                                    );
                                                }

                                                return selected.join(", ");
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                        >
                                            <MenuItem disabled value="">
                                                Select Gender
                                            </MenuItem>
                                            <MenuItem key={"male"} value={"male"}>
                                                {"Male"}
                                            </MenuItem>
                                            <MenuItem key={"Female"} value={"Female"}>
                                                {"Female"}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <input
                                    type={"date"}

                                    className="app-login-form-field"
                                    {...register("tenent_dob")}
                                />
                                {errors?.tenent_dob && (
                                    <span style={{ color: "red" }}>
                                        {errors?.tenent_dob?.message}
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <div className="app-register-form-field">
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={12} lg={6}>
                                <input
                                    type={"text"}
                                    placeholder="Father first name"
                                    className="app-login-form-field"
                                    {...register("tenent_father_first_name")}
                                />
                                {errors?.tenent_father_first_name && (
                                    <span style={{ color: "red" }}>
                                        {errors?.tenent_father_first_name?.message}
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <input
                                    type={"text"}
                                    placeholder="Father last name"
                                    className="app-login-form-field"
                                    {...register("tenent_father_last_name")}
                                />
                                {errors?.tenent_father_last_name && (
                                    <span style={{ color: "red" }}>
                                        {errors?.tenent_father_last_name?.message}
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <div className="app-register-form-field">
                        <Grid container spacing={2} style={{ marginTop: "10px" ,display:'flex',alignItems:'center'}}>
                        <Grid item xs={12} lg={6} >
                                <label>Relation With Primary Tenant</label>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <input
                                    type={"text"}
                                    placeholder="Enter here"
                                    className="app-login-form-field"
                                    {...register("relation_with_primary_tenant")}
                                />
                                {errors?.relation_with_primary_tenant && (
                                    <span style={{ color: "red" }}>
                                        {errors?.relation_with_primary_tenant?.message}
                                    </span>
                                )}
                            </Grid>
                           
                        </Grid>
                    </div>
                </li>
               

                <li className="app-register-form-field-group-item">
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="app-register-form-field">
                                <Grid container spacing={2}>
                                    <Grid item md={12} lg={6}>
                                        <div className="app-register-form-field">
                                            <label className="app-register-form-field-label">
                                                Id Type
                                            </label>
                                            <input
                                                type={"text"}
                                                value={"Aadhar"}
                                                disabled
                                                placeholder="enter id number"
                                                className="app-login-form-field"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item md={12} lg={6}>
                                        <div className="app-register-form-field">
                                            <label className="app-register-form-field-label">
                                                ID Number
                                            </label>
                                            <input
                                                type={"text"}
                                                disabled={isEdit != null ? true : false}
                                                placeholder="enter id number"
                                                className="app-login-form-field"
                                                {...register("id_number")}
                                            />

                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </li>

                <div style={{ display: "flex", justifyContent: 'center', marginTop: 40 }}>

                    <Button
                        onClick={() => { }}
                        className="app-register-form-button"
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {isEdit == null ? "Add" : "Edit"}
                    </Button>
                </div>
            </form>
        </div>
    )
}
