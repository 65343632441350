import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FamilyMember from '../pages/FamilyMember';

export default function AddTenent({isEdit,getTenetVerifiedDetails,open,setFamily_members_data,handleClose,tenant_details}) {


  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Family member
          <div className="close-icon-btn">
            <Button onClick={handleClose} aria-label="delete">
              <img src="/images/close.svg"/>
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
         <FamilyMember isEdit={isEdit} handleClose={handleClose} getTenetVerifiedDetails={getTenetVerifiedDetails} tenant_details={tenant_details} setFamily_members_data={setFamily_members_data} />
        </DialogContent>
      </Dialog>
    </div>
  );
}