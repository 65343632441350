import React from 'react'
import "../styles/pages/landing-page.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
export default function RefundPolicy() {
  return (
    <div className="app-landing-page new-landing">
      <div className="app-landing-page-header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/">
              <img className="app-landing-page-logo" src="/images/landing-page/logo3.png" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="home/#services">Services</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="home/#about">About</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="home/#contactus">Contact Us</a>
                </li>
                <li>
                  <Link to="/login">
                    <Button type="button" className="app-home-cta" variant="contained">Get Started</Button>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className='seperator'>
      <div className="policy-statement">
        <div className="container">
          <h1>Refund Policy</h1>
          <p>Refund policy doesn't apply for rent payments and biller payments if the amount is already credited to the beneficiary.</p>
          <p>Amount will be paid to the landlord or biller bank account registered with us as per the request.</p>
          <p>If you have made a transaction at score10 & want to cancel or refund the transaction,you can immediately write to us at support@score10.in within 1 hour of making the transaction with a valid cancellation reason</p>
          <p>Also please note, any cancellation will incur a cost of 2% Refund/Cancellation Request Charges along with convenience fee charged.
          </p>
          <p>Once a request is received & cancellation is processed, it will take up to 7 bank working days for the transaction to be credited back to the source of payment.
          </p>
        </div>
      </div>
      <div className="app-landing-footer">
        <div className=''>
          <div className="app-landing-footer-content">
            <div className="app-landing-footer-highlight">
              <img src="/images/landing-page/logo3.png" />
              <div className="ml-3">
                <p>Score10 <span>TM</span> is a product of Beatroot Finserv Pvt Ltd.</p>
                <h6>CIN: 	U72500TG2022PTC161895</h6>
              </div>
            </div>
            <ul>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#contactus">
                  Contact Us
                </a>
              </li>
              <li>
                <Link to="/terms-and-conditions">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                  <Link to="/refund-policy">
                  Refund Policy
                  </Link>
                </li>
              <li>
                <Link to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="copy-right-content">

          </div>
        </div>
      </div>
      </div>
    
    </div>
  )
}
