import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const validationSchema = yup
    .object({
        category: yup.string().required("It is a required field"),
        description: yup.string().required("It is a required field"),

    })

export default function RaiseTicket() {
    const { id } = useParams();
    const location = useLocation();
    const {
        register,
        setValue,
        getValues,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [tenantAdded, setTenantAdded] = useState(false)
    const [linkError, setLinkError] = useState(false)

    const [personName2, setPersonName2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shortLinkDetails, setShortLinkDetails] = useState(null);


    const handleChange4 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName2(
            typeof value === "string" ? value.split(",") : value
        );
        setValue("category", value)
    };

    const apiCall = async () => {
        try {
            setLoading(true)
            let formData = getValues();
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            const headers = { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } }
            if (shortStatus == 200) {
                const { status } = await axios.post(`${LIVEURLNEW2}issueTicket`, { ...formData,linkId:id }, headers);
                if (status == 201 || status == 200) {
                    setTenantAdded(true)
                    toast.success("Ticket created successfully")
                    window.location.href = `whatsapp://send?phone=+919505895078`;
                    setLoading(false)
                    return;
                }
                toast.error("Something went wrong")
                setLoading(false)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error("Something went wrong")
        }
    }
    const getShortLinkDetails = async () => {

        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`);
            if (shortStatus == 200) {
                setShortLinkDetails(shortLinkUrl)
                return
            }
            setLinkError(true)
        } catch (e) {
            setLinkError(true)
        }
    }

    useEffect(() => {
        getShortLinkDetails();
    }, [])


    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                {linkError ? <div className='app-new-auth-page-card app-new-auth-page-card-tenant' style={{ color: 'white' }}>
                    <div className='d-flex justify-content-center align-items-center flex-column'>
                        <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                        <span className='font-weight-bold'>Link got expired</span>
                    </div>
                </div> : tenantAdded ?
                    <div className='app-new-auth-page-card app-new-auth-page-card-tenant' style={{ color: 'white' }}>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                            <span className='font-weight-bold'>Ticket raised successfully</span>
                        </div>
                    </div> : <form onSubmit={handleSubmit(apiCall)}>
                        <div className="container h-100">
                            <div className="app-new-auth-page-wrapper">
                                <div className="app-new-auth-page-heading">
                                    <h1>Welcome</h1>
                                    <p>Raise a Ticket for an issue your facing</p>
                                </div>
                                <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                                    <ul className="app-login-form-field-group">
                                        <li>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={12}>
                                                                <div className="app-register-form-field">
                                                                    <FormControl className="w-100" sx={{}}>
                                                                        <Select
                                                                            displayEmpty
                                                                            className="app-custom-select"
                                                                            value={personName2}
                                                                            onChange={handleChange4}
                                                                            IconComponent={DownChevron}
                                                                            input={<OutlinedInput />}
                                                                            renderValue={(selected) => {
                                                                                if (selected.length === 0) {
                                                                                    return (
                                                                                        <span className="app-custom-select-placeholder">
                                                                                            Please select issue category
                                                                                        </span>
                                                                                    );
                                                                                }
                                                                                return selected.join(", ");
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                Please select Issue Category
                                                                            </MenuItem>
                                                                            <MenuItem key={"Tenant Verification"} value={"Tenant Verification"}>
                                                                                Tenant Verification
                                                                            </MenuItem>
                                                                            <MenuItem key={"Account creation"} value={"Account creation"}>
                                                                                Account creation
                                                                            </MenuItem>
                                                                            <MenuItem key={"Property creation"} value={"Property creation"}>
                                                                                Property creation
                                                                            </MenuItem>
                                                                            <MenuItem key={"Tenant creation"} value={"Tenant creation"}>
                                                                                Tenant creation
                                                                            </MenuItem>
                                                                            <MenuItem key={"Verification Request"} value={"Verification Request"}>
                                                                                Verification Request
                                                                            </MenuItem>
                                                                            <MenuItem key={"Shortlisting new tenants"} value={"Shortlisting new tenants"}>
                                                                                Shortlisting new tenants
                                                                            </MenuItem>
                                                                            <MenuItem key={"Payment related issues"} value={"Payment related issues"}>
                                                                                Payment related issues
                                                                            </MenuItem>
                                                                            <MenuItem key={"Others"} value={"Others"}>
                                                                                Others
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    {errors?.category && <span className="app-warning-content">{errors?.category?.message}</span>}

                                                                </div>

                                                            </Grid>

                                                        </Grid>
                                                    </div>



                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={12}>
                                                                <textarea placeholder='Please enter your description here...' style={{ width: '100%' }} {...register("description")} rows="5"></textarea>
                                                                {errors?.description && <span className="app-warning-content">{errors?.description?.message}</span>}

                                                            </Grid>

                                                        </Grid>
                                                    </div>


                                                </Grid>
                                            </Grid>
                                        </li>
                                        <li>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="app-auth-button justify-content-center">
                                                        <Button
                                                            variant="contained"
                                                            type="submit"
                                                        >
                                                            {loading ? <CircularProgress size={25} color='inherit' /> : 'Raise Ticket'}
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </form>}
            </div>
        </>
    )
}
