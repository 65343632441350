import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import Button from '@mui/material/Button';
import DownChevron from "../../icons/DownChevron";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { loginSchema } from "../../utils/validations";
import { LIVEURL } from "../../utils/constant";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";
import { toast } from "react-toastify";
import { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { UserRoleContext } from "../../utils/userAuthorization";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: "0" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function Login() {
    const roleContext = useContext(UserRoleContext);
    const [gender, setgender] = React.useState([]);
    const navigate = useNavigate();
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
    });

    const handleChange2 = (event) => {
        const {
            target: { value },
        } = event;
        setgender(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );



        setValue("login_type", "panCard")
        console.log(value)
    };



    const onSubmit = async (formData) => {
        const payload = {
            email: formData.email,
            password: formData.password,
        };
        console.log(gender)
        axios.post(tab == 0 ? `${LIVEURL}user/login` : `${LIVEURL}tenant/login`, payload).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("token", res.data?.accessToken)
                localStorage.setItem("isAdmin", res.data?.isAdmin)
                roleContext.updateContext({ isLoggedIn: true });
                if (res.data?.isAdmin) {
                    navigate("/landlord")
                } else {
                    localStorage.setItem("user", JSON.stringify(res.data?.user))
                    navigate("/tenant-form")
                }
            }
        }).catch((e) => {
            if (e.response.status == 404) {
                toast.error(e.response?.data?.message)
            }
            else if (e.response.status == 401) {
                toast.error(e.response?.data?.message)
            }
            else if (e.response.status == 400) {
                toast.error(e.response?.data?.message)
            }
        });
    };
    const [tab, setTab] = React.useState(0);

    const handleChange = (event, newTab) => {
        setTab(newTab);
    };
    console.log(tab)
    return (
        <>
        <header>
            <div className="container">
                <div className="app-new-auth-header">
                    <img className="app-new-auth-header-icon" onClick={() => {
                        navigate("/")
                    }} src="/images/landing-page/logo3.png"/>
                    <Link to="/register">
                        <Button className="text-size-control">
                            <img src="/images/plus-icon.svg"/>
                            Create landlord's account
                        </Button>
                    </Link>
                </div>
            </div>
        </header>
        
        <div className="app-new-auth-page login-bg-image">
            <div className="container h-100">
                <div className="app-new-auth-page-wrapper">
                    <div className="app-new-auth-page-heading">
                        <h1>Welcome</h1>
                        <p>Sign In to Your Account</p>
                    </div>
                    <div className="app-new-auth-page-card">
                        <div className="app-new-auth-page-card-tab">
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tab} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example">
                                    <Tab label="Landlord" {...a11yProps(0)} />
                                    <Tab label="Tenant" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tab} index={0}>
                                    <div className="">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <ul className="app-login-form-field-group">
                                                <li className="d-none">
                                                    <FormControl className="w-100" sx={{}}>
                                                        <Select
                                                            displayEmpty
                                                            className="app-custom-select"
                                                            value={gender}
                                                            onChange={handleChange2}
                                                            IconComponent={DownChevron}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return (
                                                                        <span className="app-custom-select-placeholder">
                                                                            Select Login type
                                                                        </span>
                                                                    );
                                                                }
                                                                return selected.join(", ");
                                                            }}
                                                            MenuProps={MenuProps}
                                                            inputProps={{
                                                                "aria-label": "Without label",
                                                            }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                Select login type
                                                            </MenuItem>
                                                            <MenuItem key={"landlord"} value={"landlord"}>
                                                                {"Landlord"}
                                                            </MenuItem>
                                                            <MenuItem key={"tenant"} value={"tenant"}>
                                                                {"Tenant"}
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {errors?.login_type && <span className="app-warning-content"> <img src="/images/caution.svg"/> {errors?.login_type?.message}</span>}
                                                </li>
                                                <li>
                                                    <input className="app-login-form-field" placeholder="Email or Username" {...register("email")} />
                                                    {errors?.email && <span className="app-warning-content"><img src="/images/caution.svg"/> {errors?.email?.message}</span>}
                                                </li>
                                                <li>
                                                    <input className="app-login-form-field" type={'password'} placeholder="Password" {...register("password")} />
                                                    {errors?.password && <span className="app-warning-content"><img src="/images/caution.svg"/> {errors?.password?.message}</span>}
                                                </li>
                                                <li className="d-flex justify-content-end">
                                                <p onClick={() => {localStorage.setItem("isUser",false);navigate("/sendEmail")}} className="forgot-password cursor-pointer">Forgot password ?</p>

                                               
                                                </li>
                                            </ul>
                                            <div className="app-auth-button">
                                                <Button type="submit" variant="contained">Login</Button>
                                            </div>
                                            <p className="app-login-form-button-alt">Don't have an account?  <Link to="/register">Register</Link></p>
                                        </form>
                                        <div className="app-view-stepper">
                                            <ul className="app-view-stepper-list">
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 1</h6>
                                                        <p>Register Yourself</p>
                                                    </div>
                                                </li>
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 2</h6>
                                                        <p>Add Your Tenant</p>
                                                    </div>
                                                </li>
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 3</h6>
                                                        <p>Send them Login Details</p>
                                                    </div>
                                                </li>
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 4</h6>
                                                        <p>Request them to Login & Verify ID and Address</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                <div className="">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <ul className="app-login-form-field-group">
                                                <li className="d-none">
                                                    <FormControl className="w-100" sx={{}}>
                                                        <Select
                                                            displayEmpty
                                                            className="app-custom-select"
                                                            value={gender}
                                                            onChange={handleChange2}
                                                            IconComponent={DownChevron}
                                                            input={<OutlinedInput />}
                                                            renderValue={(selected) => {
                                                                if (selected.length === 0) {
                                                                    return (
                                                                        <span className="app-custom-select-placeholder">
                                                                            Select Login type
                                                                        </span>
                                                                    );
                                                                }
                                                                return selected.join(", ");
                                                            }}
                                                            MenuProps={MenuProps}
                                                            inputProps={{
                                                                "aria-label": "Without label",
                                                            }}
                                                        >
                                                            <MenuItem disabled value="">
                                                                Select login type
                                                            </MenuItem>
                                                            <MenuItem key={"landlord"} value={"landlord"}>
                                                                {"Landlord"}
                                                            </MenuItem>
                                                            <MenuItem key={"tenant"} value={"tenant"}>
                                                                {"Tenant"}
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {errors?.login_type && <span className="app-warning-content"> <img src="/images/caution.svg"/> {errors?.login_type?.message}</span>}

                                                </li>
                                                <li>
                                                    <input className="app-login-form-field" placeholder="Email or Username" {...register("email")} />
                                                    {errors?.email && <span className="app-warning-content"><img src="/images/caution.svg"/> {errors?.email?.message}</span>}
                                                </li>
                                                <li>
                                                    <input className="app-login-form-field" type={'password'} placeholder="Password" {...register("password")} />
                                                    {errors?.password && <span className="app-warning-content"><img src="/images/caution.svg"/> {errors?.password?.message}</span>}
                                                </li>
                                                <li className="d-flex justify-content-end">
                                                <p onClick={() => {localStorage.setItem("isUser",true);
                                                 navigate("/sendEmail")}} className="forgot-password">Forgot password ?</p>
                                                </li>
                                            </ul>
                                            <div className="app-auth-button">
                                                <Button type="submit" variant="contained">Login</Button>
                                            </div>
                                        </form>
                                        <div className="app-view-stepper">
                                            <ul className="app-view-stepper-list">
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 1</h6>
                                                        <p>Login with Details received on your Email/SMS</p>
                                                    </div>
                                                </li>
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 2</h6>
                                                        <p>Add Your Current Address</p>
                                                    </div>
                                                </li>
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 3</h6>
                                                        <p>Verify your ID & Address</p>
                                                    </div>
                                                </li>
                                                <li className="app-view-stepper-list-item">
                                                    <div className="app-view-stepper-list-item-info">
                                                        <h6>Step 4</h6>
                                                        <p>Add your Family members & Verify their Identity</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login