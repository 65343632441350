import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function LandlordProperties() {
    const { id } = useParams();
    const [propertyList, setPropertyList] = useState(null);
    const [shortLinkDetails, setShortLinkDetails] = useState(null)
    const [error, setError] = useState(false)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const getProperties = async () => {
        setLoading(true)
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            setShortLinkDetails(shortLinkUrl)
            if (shortStatus == 200) {
                const { data, status } = await axios.get(`${LIVEURLNEW2}property`, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } });
                if (status == 200) {
                    setPropertyList(data)
                }
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            setError(true)
        }
    }

    useEffect(() => {
        getProperties();
    }, [])

    const openWhatsApp = () => {
        window.location.href = `whatsapp://send?phone=+919505895078&text=${encodeURIComponent("Hi")}`;
    };



    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Property List</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : <ul className="app-login-form-field-group">
                                <li>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="app-register-form-field">
                                                <Grid container spacing={2}>
                                                    {
                                                        propertyList?.map((item) => {
                                                            return <Grid item xs={12} lg={6}>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src="/images/defaultProperty.jpg" style={{ height: '100px', width: '100px' }} />
                                                                    <div className='d-flex flex-column'>
                                                                    <span className='font-weight-bold'>{item?.flat_no}</span>
                                                                        <span className='font-weight-bold'>{item?.building_name}</span>
                                                                        <span>{item?.address}</span>
                                                                        <span className='fs-14'>{item?.city},{item?.pincode}</span>
                                                                        <span className='fs-14'>{item?.furnishing_status}</span>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        })
                                                    }



                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </li>
                                {!loading && <li>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="app-auth-button justify-content-center">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    onClick={() => {
                                                        navigate(`/createProperty/${propertyList[0]?.landlord?._id}`,{ state: { from: "propertyList" }})
                                                    }}
                                                >
                                                    {loading ? <CircularProgress size={25} color='inherit' /> : 'Add new property'}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </li>}
                            </ul>
                            }
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="app-auth-button justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={openWhatsApp}

                                        >
                                            {'Back to whatsapp'}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
