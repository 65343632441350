import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import DownChevron from '../../icons/DownChevron';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { registerSchema } from '../../utils/validations';
import axios from 'axios';
import { BASEURL, LIVEURL } from '../../utils/constant';
import { useContext } from "react";
import { UserRoleContext } from "../../utils/userAuthorization";
import { toast } from 'react-toastify';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'India',
    'AUS',
    'USA',
];
const title = [
    'Mr',
    'Mrs',
    'Miss',
];
function Register() {
    const navigate = useNavigate();
    const roleContext = useContext(UserRoleContext);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const handleChange2 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(registerSchema),
    });
    const onSubmit = async (formData) => {
        if (formData.password != formData?.confirm_password) {
            alert("password and confirm password should be same")
            return
        }
        const payload = {
            email: formData.email, first_name: formData.first_name,
            last_name: formData.last_name, password: formData.password, mobile: formData.mobile
        }
        axios.post(`${LIVEURL}user/register`, payload).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("token",res.data?.accessToken)
                roleContext.updateContext({ isLoggedIn: true });
                navigate("/landlord")
            } 
        }).catch((e) =>{
            if(e.response.status == 400){
                toast.error(e.response?.data?.message)
            }
        });
    };
    return (
        <>
         <header>
            <div className="container">
                <div className="app-new-auth-header">
                    <img onClick={() => {
                        navigate("/")
                    }} className="app-new-auth-header-icon" src="/images/landing-page/logo3.png"/>
                    <Link to="/login">
                        <Button>
                            <img src="/images/sign-in-icon.svg"/>
                            Sign In
                        </Button>
                    </Link>
                </div>
            </div>
        </header>
        <div className="app-new-auth-page register-bg-image">
            <div className="container h-100">
                <div className="app-new-auth-page-wrapper">
                    <div className="app-new-auth-page-heading">
                        <h1>Welcome</h1>
                        <p>Create New Score10 Landlord's Account</p>
                    </div>
                    <div className="app-new-auth-page-card">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <ul className="app-login-form-field-group">
                            <li>
                            <input type={'text'} placeholder="First Name" className="app-login-form-field" {...register("first_name")} />
                            {errors?.first_name && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.first_name?.message}</span>}
                            </li>
                            <li>
                            <input type={'text'} placeholder="Last Name" className="app-login-form-field" {...register("last_name")} />
                            {errors?.last_name && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.last_name?.message}</span>}
                            </li>
                            <li>
                                <input type={'text'} placeholder="Email Address" className="app-login-form-field"  {...register("email")} />
                                {errors?.email && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.email?.message}</span>}
                            </li>
                            <li>
                                <input type={'text'} placeholder="Contact number" className="app-login-form-field" {...register("mobile")} />
                                {errors?.mobile && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.mobile?.message}</span>}
                            </li>
                            <li>
                                <input type={'password'} placeholder="Password" className="app-login-form-field" {...register("password")} />
                                {errors?.password && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.password?.message}</span>}
                            </li>
                            <li>
                                <input type={'password'} placeholder="Confirm Password" className="app-login-form-field" {...register("confirm_password")} />
                                
                            </li>
                        </ul>
                        <div className="app-auth-button">
                            <Button type="submit" variant="contained">Create Landlord's Account</Button>
                        </div>
                        <p className="app-login-form-button-alt">I already have an account <Link to="/login">Login</Link></p>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Register