import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function RentalAgreement() {
    const { id } = useParams();
    const [propertyList, setPropertyList] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [shortLinkDetails, setShortLinkDetails] = useState(null)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const getProperties = async () => {
        setLoading(true)
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            setShortLinkDetails(shortLinkUrl)
            if (shortStatus == 200) {
                const { data, status } = await axios.get(`${LIVEURLNEW2}landlordTenantProperty/`, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } });
                if (status == 200) {
                    data?.map((item) => {
                        setPropertyList((prev) => {
                            return [...prev, { value: item?._id, label: item?.property?.building_name, details: item }]
                        });
                    })
                }
                setLoading(false)
            }
        } catch (e) {
            setError(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        if(location?.state?.details){
            setSelectedProperty(location?.state?.details)
        }
        getProperties();
    }, [])

    
    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Rental Agreement</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : <ul className="app-login-form-field-group">
                                {!location?.state?.details && <>
                                    <p>Please Select the property for rental agreement</p>
                                    <Select onChange={(e) => {
                                        setSelectedProperty(e?.details)
                                    }} options={propertyList} />
                                </>}

                                {selectedProperty && <li>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="app-register-form-field">
                                                <Grid container spacing={2}>

                                                    <Grid item xs={12} lg={6}>
                                                        <div className='d-flex align-items-center' style={{ marginTop: 20 }}>
                                                            <img src="/images/defaultProperty.jpg" style={{ height: '100px', width: '100px' }} />
                                                            <div className='d-flex flex-column'>
                                                                <span className='font-weight-bold'>{selectedProperty?.property?.building_name}</span>
                                                                <span>{selectedProperty?.property?.address}</span>
                                                                <span className='fs-14'> Face : {selectedProperty?.property?.property_face}</span>
                                                                <span className='fs-14'> Status : {selectedProperty?.property?.property_status}</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6}>
                                                        <div className='d-flex align-items-center' style={{ marginTop: 20 }}>
                                                            <img src="/images/default-user.png" style={{ height: '75px', width: '75px', borderRadius: 50, marginRight: 15 }} />
                                                            <div className='d-flex flex-column'>
                                                                <span className='font-weight-bold'>{selectedProperty?.tenant?.first_name} {selectedProperty?.tenant?.last_name}</span>
                                                                <span>{selectedProperty?.tenant?.mobile}</span>
                                                                <span className='fs-14'> {selectedProperty?.tenant?.email}</span>
                                                            </div>
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </li>}
                            </ul>}

                            {!loading && !error &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className="app-auth-button justify-content-center">
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    navigate(`/PaymentLink/${id}`, { state: { details: selectedProperty } })
                                                }}
                                            >
                                                {loading ? <CircularProgress size={25} color='inherit' /> : 'Pay for agreement'}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
