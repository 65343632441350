import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableNoData from "./NoTableData";
import Button from "@mui/material/Button";
import Label from "./label";

export default function RegisteredLandlords({
  dashboardData,
  loading,
  dowloadPDF,
  getVerificationDetails
}) {
  return (
    <TableContainer component={Paper} style={{ marginTop: 20 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>sl no</TableCell>

            <TableCell>Owner Name</TableCell>
            <TableCell>Owner Mobile</TableCell>
            <TableCell>Tenant Name</TableCell>
            <TableCell>Tenant Mobile</TableCell>
            <TableCell>Flat Number</TableCell>
            <TableCell>Block</TableCell>
            <TableCell>Is Verified</TableCell>
            <TableCell align={'center'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(!dashboardData || dashboardData?.length == 0) && !loading && (
            <TableNoData />
          )}
          {dashboardData?.map((row,index) => (
            <TableRow
              key={1}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
                 <TableCell>
                {index+1}
              </TableCell>
              <TableCell>
                {row?.landlord?.first_name} {row?.landlord?.last_name}
              </TableCell>
              <TableCell>{row?.landlord?.mobile}</TableCell>
              <TableCell>
                {row?.tenant?.first_name}{" "}
                {row?.tenant?.last_name}
              </TableCell>
              <TableCell>
                {row?.tenant?.mobile}
              </TableCell>
              <TableCell>{row?.property?.flat_no}</TableCell>
              <TableCell>{row?.property?.block_no}</TableCell>
              <TableCell>
                    <Label color={(!row?.tenant?.generatedReportUrl && 'error') || 'success'}>{row?.tenant?.generatedReportUrl ? 'Yes' : 'No'}</Label>
              </TableCell>
              <TableCell style={{display:'flex',flexDirection:'row',flexWrap:"wrap",justifyContent: 'center'}}>
                <Button
                  disabled={row?.disable}
                  style={{ marginTop: 10, marginRight:10}}
                  onClick={() => {
                    dowloadPDF(
                      row?.tenant?.generatedReportUrl
                        ?.Location
                    );
                  }}
                  variant="contained"
                >
                  Tenant Report
                </Button>
                <Button
                  disabled={row?.disable}
                  style={{ marginTop: 10 }}
                  onClick={() => {
                    
                    getVerificationDetails(row)
                  }}
                  variant="contained"
                >
                  Detailed View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
