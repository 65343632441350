import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SelectNew from 'react-select';
import * as yup from "yup";
import "yup-phone";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



export default function ConfirmTenant() {

    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [shortLinkDetails, setShortLinkDetails] = useState(null)
    const [error, setError] = useState(false)
    const [tenantAdded, setTenantAdded] = useState(false)
    const location = useLocation()
    const { details } = location.state;
    const getValidationSchema = () => {
        return yup
            .object({
                notice_period: yup.number().integer('Must be an integer').min(1, 'Must be greater than or equal to 1').max(12, 'Must be less than or equal to 10').required("It is a required field"),
                rent_amount: yup.string().required("It is a required field"),
                security_deposit: yup.string().required("It is a required field"),
                repayment_date: yup.number().integer('Must be an integer').min(1, 'Must be greater than or equal to 1').max(10, 'Must be less than or equal to 10').required("It is a required field"),
            })
    }

    const validationSchema = getValidationSchema();

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const getShortLinkDetails = async () => {
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            if (shortStatus == 200) {
                setShortLinkDetails(shortLinkUrl)
            }
        } catch (e) {
            setError(true)
        }
    }


    useEffect(() => {
        getShortLinkDetails();
    }, [])

    const apiCall = async () => {
        if (loading) return;
        setLoading(true)
        try {
            let formData = getValues();
            const headers = { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } }
            const payload = { ...formData,tenant_type: "Existing", landlord_status : "Accepted"}
            const { status } = await axios.patch(`${LIVEURLNEW2}landlordTenantProperty/${details?._id}`, payload, headers);
            if (status == 201 || status == 200) {
                toast.success("Tenant confirmed successfully")
                setLoading(false)
                setTenantAdded(true)
                window.location.href = `whatsapp://send?phone=+919505895078`;
                return;
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            if (e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
                return
            }

            toast.error("Something went wrong, Please try again");
        }
    }

    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Please enter below details to confirm tenant</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : tenantAdded ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Tenant confirmed successfully</span>
                            </div> : <form onSubmit={handleSubmit(apiCall)}>
                                <ul className="app-login-form-field-group">
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>

                                                {<div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Notice period (in months)"
                                                                className="app-login-form-field"
                                                                {...register("notice_period")}
                                                            />
                                                            {errors?.notice_period && <span className="app-warning-content">{errors?.notice_period?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Rent amount"
                                                                className="app-login-form-field"
                                                                {...register("rent_amount")}
                                                            />
                                                            {errors?.rent_amount && <span className="app-warning-content">{errors?.rent_amount?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                                {<div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Security deposit amount"
                                                                className="app-login-form-field"
                                                                {...register("security_deposit")}
                                                            />
                                                            {errors?.security_deposit && <span className="app-warning-content">{errors?.security_deposit?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Rent payment day every month (1 to 10)"
                                                                className="app-login-form-field"
                                                                {...register("repayment_date")}
                                                            />
                                                            {errors?.repayment_date && <span className="app-warning-content">{errors?.repayment_date?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-auth-button justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Confirm'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
