import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SelectNew from 'react-select';
import * as yup from "yup";
import "yup-phone";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



export default function AddTenant() {

    const { id } = useParams();


    const [propertyList, setPropertyList] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [transactionDetails, setTransactionDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shortLinkDetails, setShortLinkDetails] = useState(null)
    const [error, setError] = useState(false)
    const [tenantAdded, setTenantAdded] = useState(false)
    const getValidationSchema = () => {
        const PHONE_NO_REGEX = /^[0-9\- ]{8,14}$/
        return yup
            .object({
                first_name: yup.string().required("It is a required field"),
                last_name: yup.string().required("It is a required field"),
                email: yup.string().email().required("It is a required field"),
                mobile: yup.string().matches(PHONE_NO_REGEX, { message: "not a valid phone no", excludeEmptyString: true }).length(10),
                property: yup.string().required("It is a required field"),
                ...(shortLinkDetails?.tenantType == "Existing" && {
                    notice_period: yup.number().integer('Must be an integer').min(1, 'Must be greater than or equal to 1').max(12, 'Must be less than or equal to 10').required("It is a required field"),
                    rent_amount: yup.string().required("It is a required field"),
                    security_deposit: yup.string().required("It is a required field"),
                    repayment_date: yup.number().integer('Must be an integer').min(1, 'Must be greater than or equal to 1').max(10, 'Must be less than or equal to 10').required("It is a required field"),
                }),
            })
    }

    const validationSchema = getValidationSchema();

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const getProperties = async () => {
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            setShortLinkDetails(shortLinkUrl)
            if (shortStatus == 200) {
                const { data, status } = await axios.get(`${LIVEURLNEW2}property/`, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } });
                if (status == 200) {
                    data?.map((item) => {
                        setPropertyList((prev) => {
                            return [...prev, { value: item?._id, label: item?.building_name }]
                        });
                    })
                    if (data?.length == 1) {
                        setSelectedProperty(data[0]?._id);
                        setValue("property", data[0]?._id)
                    }
                    return;
                }
            }
        } catch (e) {
            setError(true)
        }
    }


    useEffect(() => {
        getProperties();
    }, []);

    const redeemCoupon = async () => {
        try {
            const { status, data } = await axios.patch(`${LIVEURLNEW2}rewards/`, {}, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            return true
        } catch (e) {
            return false
        }

    }

    const createTenant = async (transId, isRedeem) => {
        if (loading) return;
        if (propertyList?.length > 1 && !selectedProperty) {
            toast.error("Please select property");
            return
        }
        setLoading(true)
        try {
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            if (shortStatus == 200) {
                let formData = getValues();
                const headers = { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } }
                const payload = { ...formData, shortLinkId: id, type: shortLinkUrl?.tenantType, mobile: `91${formData?.mobile}` }
                const { status } = await axios.post(`${LIVEURLNEW2}tenant`, payload, headers);
                if (status == 201 || status == 200) {
                    await updatePaymentTransaction("Service Availed", null, transId, "createApi")
                    if (isRedeem) await redeemCoupon();
                    toast.success("Tenant added successfully")
                    setLoading(false)
                    setTenantAdded(true)
                    window.location.href = `whatsapp://send?phone=+919505895078`;
                    return;
                }
                setLoading(false)
            }

        } catch (e) {
            setLoading(false)
            if (e?.response?.data?.message) {
                toast.error(e?.response?.data?.message);
                return
            }

            toast.error("Something went wrong, Please try again");
        }
    }

    const createTransaction = async (isRedeem) => {
        try {
            setLoading(true)
            if (isRedeem) {
                const payload = {
                    "status": "Success",
                    "property": selectedProperty,
                    "paymentType": "Verification",
                    "amount": 0,
                    "orderAmount": 0,
                    "convenienceFee": 0.0,
                    "gstAmount": 0.0,
                    "amountExcludingGST":0.0,
                    "platformFee": 0.0,
                    "paymentGateway": "Razorpay",
                    "gstPercentage": 18,
                    "gstName": "IGST",
                    "transactionDetails": {}
                }
                const { status, data } = await axios.post(`${LIVEURLNEW2}paymentHistory`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
                if (status == 200 || status == 201) {
                    createTenant(data?._id, isRedeem);
                }
                setLoading(false)
                return

            }
            const payload = {
                "status": "Initiated",
                "property": selectedProperty,
                "paymentType": "Verification",
                "amount": 99,
                "orderAmount": 99 * 100,
                "convenienceFee": 0.0,
                "gstAmount": (99 * 18) / 100,
                "amountExcludingGST": 99 - ((99 * 18) / 100),
                "platformFee": 0.0,
                "gstName": "IGST",
                "gstPercentage": 18,
                "paymentGateway": "Razorpay",
                "transactionDetails": {}
            }
            const { status: paymentStatus, data } = await axios.post(`${LIVEURLNEW2}paymentHistory/createRazorpayOrderId`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                setTransactionDetails(data);
                var options = {
                    "key": "rzp_live_6VGdaqTcn0KMhz",
                    "amount": (99 * 100).toString(),
                    "currency": "INR",
                    "name": "Score10",
                    "description": "Tenant Verification Request",
                    "image": "https://score10.in/images/landing-page/logo3.png",
                    "handler": function (response) {
                        console.log(response, "responseresponse")
                        if (response?.razorpay_payment_id || response?.status == 200 ) {
                            updatePaymentTransaction("Success", { details: response }, data?._id, "transactionApi")
                        } else {
                            toast.error("Transaction failed")
                        }
                    },
                    "order_id": data?.orderDetails?.id,
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                console.log(options, "orderDetails")
                var rzp1 = new window.Razorpay(options);

                await rzp1.open();
                setLoading(false)
            }
        } catch (e) {
            toast.error("Something went wrong while initiating the transaction")
            setLoading(false);
        }
    }

    const updatePaymentTransaction = async (status, transactionDetailsNew, id, isFor) => {
        try {
            setLoading(true)
            let payload = {
                "status": status,
                "transactionDetails": transactionDetailsNew
            }
            if (transactionDetailsNew == null) delete payload.transactionDetails;
            const { status: paymentStatus } = await axios.patch(`${LIVEURLNEW2}paymentHistory/${id}`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                if (isFor == 'createApi') {
                    setLoading(false)
                    return
                }
                createTenant(id, false);
                setLoading(false)
                return true
            }
        } catch (e) {
            setLoading(false);
            return false

        }
    }

    const apiCall = async () => {
        if (loading) return;
        if (propertyList?.length > 1 && !selectedProperty) {
            toast.error("Please select property");
            return
        }
        try {
            const { status, data } = await axios.get(`${LIVEURLNEW2}rewards/VerifyFree`, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (status == 200) {
                createTransaction(true);
            } else {
                createTransaction(false);
            }

        } catch (e) {
            createTransaction(false);
        }

    }

    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Please fill the form to create tenant</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : tenantAdded ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Tenant added successfully</span>
                            </div> : <form onSubmit={handleSubmit(apiCall)}>
                                <ul className="app-login-form-field-group">
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="First name"
                                                                className="app-login-form-field"
                                                                {...register("first_name")}
                                                            />
                                                            {errors?.first_name && <span className="app-warning-content">{errors?.first_name?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Last name"
                                                                className="app-login-form-field"
                                                                {...register("last_name")}
                                                            />
                                                            {errors?.last_name && <span className="app-warning-content">{errors?.last_name?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Mobile number"
                                                                className="app-login-form-field"
                                                                {...register("mobile")}
                                                            />
                                                            {errors?.mobile && <span className="app-warning-content">{errors?.mobile?.message}</span>}

                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="email address"
                                                                className="app-login-form-field"
                                                                {...register("email")}
                                                            />
                                                            {errors?.email && <span className="app-warning-content">{errors?.email?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                {propertyList?.length > 1 && <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <SelectNew placeholder="Select property ..." onChange={(e) => { setSelectedProperty(e); setValue("property", e?.value) }} options={propertyList} />
                                                            {errors?.property && <span className="app-warning-content">{errors?.property?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                                {shortLinkDetails?.tenantType == "Existing" && <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Notice period (in months)"
                                                                className="app-login-form-field"
                                                                {...register("notice_period")}
                                                            />
                                                            {errors?.notice_period && <span className="app-warning-content">{errors?.notice_period?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Rent amount"
                                                                className="app-login-form-field"
                                                                {...register("rent_amount")}
                                                            />
                                                            {errors?.rent_amount && <span className="app-warning-content">{errors?.rent_amount?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                                {shortLinkDetails?.tenantType == "Existing" && <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Security deposit amount"
                                                                className="app-login-form-field"
                                                                {...register("security_deposit")}
                                                            />
                                                            {errors?.security_deposit && <span className="app-warning-content">{errors?.security_deposit?.message}</span>}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                placeholder="Rent payment day every month (1 to 10)"
                                                                className="app-login-form-field"
                                                                {...register("repayment_date")}
                                                            />
                                                            {errors?.repayment_date && <span className="app-warning-content">{errors?.repayment_date?.message}</span>}
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-auth-button justify-content-center">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        {loading ? <CircularProgress size={25} color='inherit' /> : 'Add tenant'}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
