import React, { useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW } from '../utils/constant';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const validationSchema = yup
    .object({
        building_name: yup.string().required("It is a required field"),
        state: yup.string().required("It is a required field"),
        city: yup.string().required("It is a required field"),
        pincode: yup.string().required("It is a required field"),
        flat_no: yup.string().required("It is a required field"),
        property_type: yup.string().required("It is a required field"),
        property_status: yup.string().required("It is a required field"),
        furnishing_status: yup.string().required("It is a required field"),
        age_of_property: yup.string().required("It is a required field"),
        property_face: yup.string().required("It is a required field"),
        address: yup.string().required("It is a required field"),


        no_of_bedooms: yup.string().required("It is a required field"),
        no_of_bathooms: yup.string().required("It is a required field"),
        no_of_carparks: yup.string().required("It is a required field"),
        square_feet: yup.string().required("It is a required field"),
    })

export default function AddProperty() {
    const { id } = useParams();
    const location = useLocation();
    const {
        register,
        setValue,
        getValues,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [tenantAdded, setTenantAdded] = useState(false)
    const [personName2, setPersonName2] = useState([]);
    const [personName3, setPersonName3] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [loading, setLoading] = useState(false);


    const handleChange4 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName2(
            typeof value === "string" ? value.split(",") : value
        );

        setValue("property_type", value)


        //setValue("id_name", "panCard")
    };

    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName3(typeof value === "string" ? value.split(",") : value)

        setValue("property_status", value)


        //setValue("id_name", "panCard")
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === "string" ? value.split(",") : value
        );

        setValue("furnishing_status", value)

        //setValue("id_name", "panCard")
    };

    const apiCall = async () => {
        try {
            setLoading(true)
            let formData = getValues();
            const { data, status } = await axios.post(`${LIVEURLNEW}addProperty`, { ...formData, landlord: id, isSendTemplete: location?.state?.from == "propertyList" ? true : false });
            if (status == 201 || status == 200) {
                setTenantAdded(true)
                toast.success("Property added successfully")
                window.location.href = `whatsapp://send?phone=+919505895078`;
                setLoading(false)
                return;
            }
            toast.error("Something went wrong")
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toast.error("Something went wrong")
        }

    }
    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                {tenantAdded ?
                    <div className='app-new-auth-page-card app-new-auth-page-card-tenant' style={{ color: 'white' }}>
                        <div className='d-flex justify-content-center align-items-center flex-column'>
                            <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                            <span className='font-weight-bold'>Property added successfully</span>
                        </div>
                    </div> : <form onSubmit={handleSubmit(apiCall)}>
                        <div className="container h-100">
                            <div className="app-new-auth-page-wrapper">
                                <div className="app-new-auth-page-heading">
                                    <h1>Welcome</h1>
                                    <p>Please Fill the form to create property</p>
                                </div>
                                <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                                    <ul className="app-login-form-field-group">
                                        <li>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="app-register-form-field">

                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Building name"
                                                                    className="app-login-form-field"
                                                                    {...register("building_name")}
                                                                />
                                                                {errors?.building_name && <span className="app-warning-content">{errors?.building_name?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Description"
                                                                    className="app-login-form-field"
                                                                    {...register("description")}
                                                                />

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="State"
                                                                    className="app-login-form-field"
                                                                    {...register("state")}
                                                                />
                                                                {errors?.state && <span className="app-warning-content">{errors?.state?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="City"
                                                                    className="app-login-form-field"
                                                                    {...register("city")}
                                                                />
                                                                {errors?.city && <span className="app-warning-content">{errors?.city?.message}</span>}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="Zip/postal code"
                                                                    className="app-login-form-field"
                                                                    {...register("pincode")}
                                                                />
                                                                {errors?.pincode && <span className="app-warning-content">{errors?.pincode?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Address"
                                                                    className="app-login-form-field"
                                                                    {...register("address")}
                                                                />
                                                                {errors?.address && <span className="app-warning-content">{errors?.address?.message}</span>}

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    min="1"
                                                                    placeholder="Flat number"
                                                                    className="app-login-form-field"
                                                                    {...register("flat_no")}
                                                                />
                                                                {errors?.flat_no && <span className="app-warning-content">{errors?.flat_no?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Property tax number"
                                                                    className="app-login-form-field"
                                                                    {...register("property_tax_number")}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <div className="app-register-form-field">
                                                                    <FormControl className="w-100" sx={{}}>
                                                                        <Select
                                                                            displayEmpty
                                                                            className="app-custom-select"
                                                                            value={personName2}
                                                                            onChange={handleChange4}
                                                                            IconComponent={DownChevron}
                                                                            input={<OutlinedInput />}
                                                                            renderValue={(selected) => {
                                                                                if (selected.length === 0) {
                                                                                    return (
                                                                                        <span className="app-custom-select-placeholder">
                                                                                            Select property type
                                                                                        </span>
                                                                                    );
                                                                                }
                                                                                return selected.join(", ");
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                Select property type
                                                                            </MenuItem>
                                                                            <MenuItem key={"Studio"} value={"Studio"}>
                                                                                Studio
                                                                            </MenuItem>
                                                                            <MenuItem key={"Apartment"} value={"Apartment"}>
                                                                                Apartment
                                                                            </MenuItem>
                                                                            <MenuItem key={"villa"} value={"villa"}>
                                                                                Villa
                                                                            </MenuItem>
                                                                            <MenuItem key={"Duplex"} value={"Duplex"}>
                                                                                Duplex
                                                                            </MenuItem>
                                                                            <MenuItem key={"Independent House"} value={"Independent House"}>
                                                                                Independent House
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    {errors?.property_type && <span className="app-warning-content">{errors?.property_type?.message}</span>}

                                                                </div>

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <div className="app-register-form-field">
                                                                    <FormControl className="w-100" sx={{}}>
                                                                        <Select
                                                                            displayEmpty
                                                                            className="app-custom-select"
                                                                            value={personName}
                                                                            onChange={handleChange}
                                                                            IconComponent={DownChevron}
                                                                            input={<OutlinedInput />}
                                                                            renderValue={(selected) => {
                                                                                if (selected.length === 0) {
                                                                                    return (
                                                                                        <span className="app-custom-select-placeholder">
                                                                                            Select furnishing status
                                                                                        </span>
                                                                                    );
                                                                                }
                                                                                return selected.join(", ");
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                Select furnishing status
                                                                            </MenuItem>
                                                                            <MenuItem key={"Fully Furnished"} value={"Fully Furnished"}>
                                                                                Fully Furnished
                                                                            </MenuItem>
                                                                            <MenuItem key={"Semi Furnished"} value={"Semi Furnished"}>
                                                                                Semi Furnished
                                                                            </MenuItem>
                                                                            <MenuItem key={"Unfurnished"} value={"Unfurnished"}>
                                                                                Unfurnished
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    {errors?.property_type && <span className="app-warning-content">{errors?.property_type?.message}</span>}

                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="Total floors"
                                                                    className="app-login-form-field"
                                                                    {...register("total_floors")}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="Floor of property"
                                                                    className="app-login-form-field"
                                                                    {...register("floor_of_property")}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="Age of property"
                                                                    className="app-login-form-field"
                                                                    {...register("age_of_property")}
                                                                />
                                                                {errors?.age_of_property && <span className="app-warning-content">{errors?.age_of_property?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="Property face"
                                                                    className="app-login-form-field"
                                                                    {...register("property_face")}
                                                                />
                                                                {errors?.property_face && <span className="app-warning-content">{errors?.property_face?.message}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="No of bedrooms"
                                                                    className="app-login-form-field"
                                                                    {...register("no_of_bedooms")}
                                                                />
                                                                {errors?.no_of_bedooms && <span className="app-warning-content">{errors?.no_of_bedooms?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="No of bathrooms"
                                                                    className="app-login-form-field"
                                                                    {...register("no_of_bathooms")}
                                                                />
                                                                {errors?.no_of_bathooms && <span className="app-warning-content">{errors?.no_of_bathooms?.message}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="No of carparks"
                                                                    className="app-login-form-field"
                                                                    {...register("no_of_carparks")}
                                                                />
                                                                {errors?.no_of_carparks && <span className="app-warning-content">{errors?.no_of_carparks?.message}</span>}

                                                            </Grid>
                                                            <Grid item xs={12} lg={6}>
                                                                <input
                                                                    type={"number"}
                                                                    placeholder="property area in sqft"
                                                                    className="app-login-form-field"
                                                                    {...register("square_feet")}
                                                                />
                                                                {errors?.square_feet && <span className="app-warning-content">{errors?.square_feet?.message}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                            <Grid item xs={12} lg={6}>
                                                                <FormControl className="w-100" sx={{}}>
                                                                    <Select
                                                                        displayEmpty
                                                                        className="app-custom-select"
                                                                        value={personName3}
                                                                        onChange={handleChange3}
                                                                        IconComponent={DownChevron}
                                                                        input={<OutlinedInput />}
                                                                        renderValue={(selected) => {
                                                                            if (selected.length === 0) {
                                                                                return (
                                                                                    <span className="app-custom-select-placeholder">
                                                                                        Select occupancy status
                                                                                    </span>
                                                                                );
                                                                            }
                                                                            return selected.join(", ");
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                        }}
                                                                    >
                                                                        <MenuItem disabled value="">
                                                                            Select occupancy status
                                                                        </MenuItem>
                                                                        <MenuItem key={"Self occupied"} value={"Self occupied"}>
                                                                            Self occupied
                                                                        </MenuItem>
                                                                        <MenuItem key={"Rented"} value={"Rented"}>
                                                                            Rented
                                                                        </MenuItem>
                                                                        <MenuItem key={"Vacant"} value={"Vacant"}>
                                                                            Vacant
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {errors?.property_status && <span className="app-warning-content">{errors?.property_status?.message}</span>}

                                                            </Grid>
                                                            {/* <Grid item xs={12} lg={6}>
                                                        <input
                                                            type={"text"}
                                                            placeholder="Property face"
                                                            className="app-login-form-field"
                                                            {...register("property_face")}
                                                        />
                                                    </Grid> */}
                                                        </Grid>
                                                    </div>

                                                </Grid>
                                            </Grid>
                                        </li>
                                        <li>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="app-auth-button justify-content-center">
                                                        <Button
                                                            variant="contained"
                                                            type="submit"
                                                        >
                                                            {loading ? <CircularProgress size={25} color='inherit' /> : 'Add property'}
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </form>}
            </div>
        </>
    )
}
