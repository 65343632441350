import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PaymentLink() {
    const { id } = useParams();
    const [shortLinkDetails, setShortLinkDetails] = useState(null);
    const location = useLocation();
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const getShortLinkDetails = async () => {
        if (!shortLinkDetails) return
        try {
            setLoading(true)
            const { details } = location.state
            // const payload = {
            //     "first_name": details?.landlord?.first_name,
            //     "mobile": details?.landlord?.mobile.toString(),
            //     "email": details?.landlord?.email,
            //     "amount": 1.00
            // }
            // const { data, status } = await axios.post(`${LIVEURLNEW2}paymentHistory/initiatePayment`, payload, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } })
            // if (status == 200) {
            //     easebuzzPayment(data?.data)
            //     return
            // }
            // setLoading(false)

            const payload = {
                "status": "Initiated",
                "property": details?.property?._id,
                "tenant": details?.tenant?._id,
                "landlordTenantProperty": details?._id,
                "paymentType": "Rental Agreement",
                "amount": 499,
                "orderAmount": 499 * 100,
                "convenienceFee": 0.0,
                "gstAmount": (499 * 18) / 100,
                "amountExcludingGST": 499 - ((499 * 18) / 100),
                "platformFee": 0.0,
                "gstName": "IGST",
                "gstPercentage": 18,
                "paymentGateway": "Razorpay",
                "transactionDetails": {}
            }
            const { status: paymentStatus, data } = await axios.post(`${LIVEURLNEW2}paymentHistory/createRazorpayOrderId`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                // setTransactionDetails(data);
                var options = {
                    "key": "rzp_live_6VGdaqTcn0KMhz",
                    "amount": (499 * 100).toString(),
                    "currency": "INR",
                    "name": "Score10",
                    "description": "Rental Agreement",
                    "image": "https://score10.in/images/landing-page/logo3.png",
                    "handler": function (response) {
                        if (response?.razorpay_payment_id || response?.status == 200) {
                            updatePaymentTransaction("Success", { details: response }, data?._id)
                        } else {
                            toast.error("Transaction failed")
                        }
                    },
                    "order_id": data?.orderDetails?.id,
                    "theme": {
                        "color": "#3399cc"
                    }
                };
                var rzp1 = new window.Razorpay(options);
                await rzp1.open();
                setLoading(false)
            }

        } catch (e) {
            setLoading(false)
        }

    }

    const getShortLinkDetailsForm = async () => {
        try {
            setLoading(true)
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`);
            if (shortStatus == 200) {
                setShortLinkDetails(shortLinkUrl)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }


    // const easebuzzPayment = (data) => {
    //     var easebuzzCheckout = new window.EasebuzzCheckout("TNNW0YJS0B", 'prod')
    //     var options = {
    //         access_key: data,
    //         onResponse: (response) => {
    //             if (response?.status == "userCancelled") {
    //                 setLoading(false)
    //                 toast.error("User cancelled transaction");
    //                 return
    //             }
    //             if (response?.status == "dropped") {
    //                 setLoading(false)
    //                 toast.error("User dropped transaction");
    //                 return
    //             }
    //             if (response?.status == "success") {
    //                 updatePaymentTransaction("Success", response);
    //             } else {
    //                 updatePaymentTransaction("Failed", response);
    //             }
    //             console.log(response);
    //         },
    //         theme: "#123456"
    //     }
    //     easebuzzCheckout.initiatePayment(options);
    // }


    const updatePaymentTransaction = async (status, transactionDetails, historyId) => {
        // try {
        //     const { details } = location.state
        //     const payload = {
        //         "status": status,
        //         "landlordTenantProperty": details?._id,
        //         "property": details?.property?._id,
        //         "paymentType": "Rental Agreement",
        //         "amount": 499.00,
        //         "convenienceFee": 0.0,
        //         "gstAmount": 0.0,
        //         "paymentGateway": "Eazebuzz",
        //         "transactionDetails": transactionDetails
        //     }
        //     const { data, status: paymentStatus } = await axios.post(`${LIVEURLNEW2}paymentHistory/`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
        //     if (paymentStatus == 200 || paymentStatus == 201) {
        //         if (status == "Failed") {
        //             toast.error("Payment Failed")
        //             setLoading(false)
        //             return
        //         }
        //         navigate(`/PreviewRentalAgreement/${id}`, { state: { details: location?.state?.details, paymentDetails: data } })
        //         setLoading(false)
        //     }
        // } catch (e) {
        //     setLoading(false);
        // }

        try {
            setLoading(true)
            let payload = {
                "status": status,
                "transactionDetails": transactionDetails
            }
            const { status: paymentStatus, data } = await axios.patch(`${LIVEURLNEW2}paymentHistory/${historyId}`, payload, { headers: { Authorization: `Bearer ${shortLinkDetails?.token}` } });
            if (paymentStatus == 200 || paymentStatus == 201) {
                navigate(`/PreviewRentalAgreement/${id}`, { state: { details: location?.state?.details, paymentDetails: data } })
                setLoading(false)
                return true
            }
        } catch (e) {
            setLoading(false);
            return false

        }
    }

    useEffect(() => {
        getShortLinkDetailsForm()
    }, [])
    const openWhatsApp = () => {
        // if (selectedProperty == null) {
        //     toast.error("Please select the property");
        //     return
        // }
        window.location.href = `whatsapp://send?phone=+919505895078`;
    };
    // updatePaymentTransaction("Success", "response");
    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Rental agreement payment</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                <span className='font-weight-bold'>Your link got expired</span>
                            </div> : <ul className="app-login-form-field-group" style={{ justifyContent: 'center', display: 'flex' }}>
                                <span style={{ textAlign: 'center' }}>Please check payment details before you pay</span>
                                <li>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className="app-register-form-field">
                                                <Grid container spacing={2}>

                                                    <Grid item xs={12} lg={6}>
                                                        <div className='d-flex justify-content-center align-items-center' style={{ marginTop: 20 }}>
                                                            <div className='d-flex flex-column'>
                                                                <div className='d-flex'>
                                                                    <div style={{ width: '160px' }}> <span className='font-weight-bold'>Amount</span></div>
                                                                    <span className='font-weight-bold'> : 499.00</span>
                                                                </div>

                                                                <div className='d-flex'>
                                                                    <div style={{ width: '160px' }}> <span className='font-weight-bold'>convenience Fee</span></div>
                                                                    <span className='font-weight-bold'> : 0.00</span>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div style={{ width: '160px' }}> <span className='font-weight-bold'>GST amount</span></div>
                                                                    <span className='font-weight-bold'> : 0.00</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>


                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </li>
                            </ul>}

                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="app-auth-button justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={getShortLinkDetails}

                                        >
                                            {loading ? <CircularProgress size={25} color='inherit' /> : 'Pay'}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
