import React, { useEffect, useState } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import { LIVEURL } from '../utils/constant';
import './verification.css'
import moment from 'moment';
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FamilyMember from './FamilyMember';
import AddTenent from '../models/addTenents';
import { UserRoleContext } from "../utils/userAuthorization";
import { useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';



export default function VerificationDetails() {
    const [open, setOpen] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(null);
    const roleContext = React.useContext(UserRoleContext);
    const navigate = useNavigate();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [tenant_details, setTenent_details] = useState(null);
    const [family_members_data, setFamily_members_data] = useState([])
    const [loading, setLoading] = useState(false)

    const getTenetVerifiedDetails = () => {
        axios.get(`${LIVEURL}tenant/`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setTenent_details(res.data)
                console.log(JSON.parse(res.data?.aadhar_details))
            } else {
                setLoading(false)

            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                toast.error(e?.response?.data?.message)
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        getTenetVerifiedDetails();
    }, [])
    const maskedIdentity = (number) => {
        console.log(number)
        let final_output = [];
    }

    const deleteFamilyTenant = (index) => {

    }
    return (
        <table className="main-wrapper">
            <tr>
                <td>
                    <table>
                        <tr>
                            <td>
                                <img className="logo" src="/images/score-10-logo.png" />
                            </td>
                            <td align="end">
                                <table>
                                    <tr>
                                        <td>Username : {tenant_details?.tenent_first_name} {tenant_details?.tenent_last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Verification id: {tenant_details?._id}</td>
                                    </tr>
                                    <tr>
                                        <Button
                                            className="noprint ml-10 app-register-form-button"
                                            variant="outlined" color="error"
                                            onClick={() => {
                                                localStorage.clear()
                                                roleContext.updateContext({ isLoggedIn: false });
                                                navigate("/login")
                                            }}
                                        >
                                            Logout
                                        </Button>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="mt-40">
                        <tr>
                            <td className="heading d-flex justify-content-center align-items-center">
                                <b></b>
                                <b>TENANT VERIFICATION REPORT</b>
                                <img src={"/images/cibil.png"} style={{ marginLeft: 40 }} />
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="table-bordered first-child-40 mt-20">
                        <tr>
                            <td>Landlord Name</td>
                            <td className="medium">{tenant_details?.landlord?.first_name} {tenant_details?.landlord?.last_name}</td>
                        </tr>
                        <tr>
                            <td>Tenant Name</td>
                            <td>{tenant_details?.tenent_first_name} {tenant_details?.tenent_last_name}</td>
                        </tr>
                        <tr>
                            <td>Current Address</td>
                            <td>{tenant_details?.tenant_address}</td>
                        </tr>
                        {/* <tr>
                        <td>CLIENT EMPLOYEE ID</td>
                        <td>18878597</td>
                    </tr> */}
                        <tr>
                            <td>REFERENCE NUMBER</td>
                            <td>SCR10#{tenant_details?._id}</td>
                        </tr>
                        <tr>
                            <td>VERIFICATION DATE</td>
                            <td>{moment(tenant_details?.createdAt).format("MMM DD YYYY HH:MM:SS")}</td>
                        </tr>
                        <tr>
                            <td>REPORT DATE</td>
                            <td>{moment(tenant_details?.createdAt).format("MMM DD YYYY HH:MM:SS")}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="mt-30">
                        <tr>
                            <td className="sub-heading" align="center">
                                <b>PRIMARY TENANT VERIFICATION DETAILS</b>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="table-bordered first-child-60 mt-20">
                        <tr>
                            <td >

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "none"
                                }}>
                                    <b>IDENTITY VERIFICATION</b> {tenant_details?.id_name == "panCard" ? "Pan Card" : tenant_details?.id_name == "drivingLicence" ? "Driving Licence" : tenant_details?.id_name == "passport" ? "Passport" : tenant_details?.id_name == "voterId" ? "Voter Id" : "Aadhaar Number : "}
                                    {tenant_details?.id_name == "panCard" ? "" : tenant_details?.id_name == "drivingLicence" ? "" : tenant_details?.id_name == "passport" ? "" : tenant_details?.id_name == "" ? "Voter Id" :tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?._attributes?.uid}
                                </div>
                            </td>
                            <td className="bg-green">VERIFIED</td>
                        </tr>
                        <tr>
                            <td>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "none"
                                }}>

                                    <b>Address VERIFICATION</b> Aadhaar Number : {tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?._attributes?.uid}

                                </div>

                            </td>
                            <td className="bg-green">VERIFIED</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="mt-30">
                        <tr>
                            <td className="sub-heading" align="center">
                                <b>DETAILS AS PER AADHAR</b>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table className="table-bordered first-child-40 mt-20">
                        <tr>
                            <td>Full name</td>
                            <td className="medium">{tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poi?._attributes?.name}</td>
                        </tr>
                        <tr>
                            <td>Father name</td>
                            <td>{tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.co.split(" ")[1]}</td>
                        </tr>
                        <tr>
                            <td>Date Of Birth</td>
                            <td>{tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poi?._attributes?.dob}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poi?._attributes?.gender == "M" ? "Male" : "Female"}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.house + " , " + tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.street + " , " +tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.vtc + " , "+tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.dist +  " , " + tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.state+" , "+tenant_details?.aadhar_details?.Certificate?.CertificateData?.KycRes?.UidData?.Poa?._attributes?.pc}</td>
                        </tr>
                    </table>
                </td>
            </tr>
        
            {(tenant_details?.more_tenents?.length < 5 || !tenant_details?.more_tenents) && <div className="noprint" style={{ display: "flex", justifyContent: 'center', marginTop: 40, marginBottom: '20px'}}>
                <Button
                    onClick={() => { handleClickOpen(); setIsEdit(null) }}
                    className="app-register-form-button"

                    variant="contained"
                >
                    Add More Tenants
                </Button>
            </div>}

            <AddTenent isEdit={isEdit} getTenetVerifiedDetails={getTenetVerifiedDetails} tenant_details={tenant_details} open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} setFamily_members_data={setFamily_members_data} />
            {/* <FamilyMember /> */}

            {tenant_details?.more_tenents?.length && <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} className="app-custom-table" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Gender</TableCell>
                            <TableCell align="left">Date of birth</TableCell>
                            <TableCell align="left">Father name</TableCell>
                            <TableCell align="left">Relationship</TableCell>
                            <TableCell align="left">ID Submitted</TableCell>
                            <TableCell align="left">Aadhar Number</TableCell>
                            <TableCell align="left">IsVerified</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tenant_details?.more_tenents?.map((row, index) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.tenent_first_name}
                                </TableCell>
                                <TableCell align="left">{row.tenent_gender}</TableCell>
                                <TableCell align="left">{row.tenent_dob}</TableCell>
                                <TableCell align="left">{row.tenent_father_first_name} {row.tenent_father_last_name}</TableCell>

                                <TableCell align="left">{row.relation_with_primary_tenant}</TableCell>
                                <TableCell align="left">{row.id_type}</TableCell>
                                <TableCell align="left">{row.id_number}</TableCell>
                                <TableCell align="left">{row.isVerified.toString().toUpperCase()}</TableCell>
                                <TableCell align="left" >
                                <Tooltip title="Edit" aria-label="edit">
                                    <Button
                                        onClick={() => { setIsEdit(index); handleClickOpen() }}
                                        style={{ marginRight: "5px" }}
                                    >
                                        <img className="button-icon-img" src="/images/edit.svg"/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Delete" aria-label="delete">
                                    <Button
                                        onClick={() => { deleteFamilyTenant(index) }}
                                        style={{ marginRight: "5px" }}
                                    >
                                        <img className="button-icon-img" src="/images/delete.svg"/>
                                    </Button>
                                    </Tooltip>
                                    {!row.isVerified && 
                                    <Tooltip title="Verify Aadhaar" aria-label="verify aadhaar">
                                    <Button
                                        onClick={() => { localStorage.setItem("aadharIndex", index) }}
                                    >
                                        <a style={{
                                            color: 'white',
                                            textDecoration: 'none'
                                        }} href="https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=CD61393B&redirect_uri=http://localhost:3000/return-url&response_type=code&state=ap"><img className="button-icon-img" src="/images/active-user.svg"/></a>
                                    </Button>
                                    </Tooltip>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}

            <div style={{ marginTop: '30px'}}>
            <span style={{
                fontSize: '18px',
                fontWeight: 600,
            }}>Aadhar photo's</span>
            <div style={{
                display: 'flex', flexWrap: 'wrap', marginTop: '10px'
            }}>
                {tenant_details?.aadhar_photo && <img src={tenant_details?.aadhar_photo} className="aadhar-photo" />}
                {
                    tenant_details?.more_tenents?.map((item) => {
                        return item?.aadhar_photo && <img src={item?.aadhar_photo} className="aadhar-photo" />
                    })
                }
            </div>
            </div>

        </table>
    )
}
