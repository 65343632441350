import React from 'react'

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableNoData from './NoTableData';
import Button from "@mui/material/Button";
import Label from './label';


export default function NonRegisteredLandlords({dashboardData,loading,resendLink}) {
  return (
    <TableContainer component={Paper} style={{marginTop:20}}>
                      <Table aria-label="simple table">
                          <TableHead>
                              <TableRow>
                                  <TableCell>sl no</TableCell>
                                  <TableCell>Mobile</TableCell>
                                  <TableCell sx={{ minWidth: 100 }}>Flat No.</TableCell>
                                  <TableCell>Block</TableCell>
                                  <TableCell>Status</TableCell>
                                  <TableCell>Action</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                         {(!dashboardData || dashboardData?.length == 0) && !loading && <TableNoData />}
                              {dashboardData?.map((row,index) => (
                                  <TableRow
                                      key={1}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell>
                                            {index+1}
                                      </TableCell>
                                      <TableCell component="th" scope="row">
                                          {row?.mobile}
                                      </TableCell>
                                      <TableCell >{row?.flat_no}</TableCell>
                                      <TableCell >{row?.block_no}</TableCell>
                                      <TableCell>
                                            <Label color={(row?.status === 'Started' && 'error') || 'success'}>{row?.status}</Label>
                                      </TableCell>
                                      {/* <TableCell >{row?.status == "Started" ? 'Sent' : row?.status}</TableCell> */}
                                      <TableCell >
                                          <Button
                                             disabled={row?.disable}
                                              style={{ marginTop: 10 }}
                                              onClick={() => {
                                                  resendLink(row)
                                              }}
                                              variant="contained"
                                          >Reshare
                                          </Button>
                                      </TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
  )
}
