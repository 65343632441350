import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import DownChevron from "../icons/DownChevron";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { tetentFormSchema, update_form } from "../../src/utils/validations/index";
import { LIVEURL } from "../utils/constant";
import axios from "axios";
import moment from "moment";
import Compress from 'compress.js'
import { toast } from "react-toastify";
import { UserRoleContext } from "../utils/userAuthorization";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";

const steps = [
    'Current Residing Address',
    'Verify ID and Address',
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const files = [
    "PAN Card (PAN Card Number)",
    "Voter ID Card (Voted ID or EPIC Number)",
    "Passport (Passport Application File Number)",
    "Driving License",
    "Adhaar Card (Adhaar or VID Number)",
    "Phone Number verification",
];
function Register() {
    const navigate = useNavigate();
    const compress = new Compress()
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [personName2, setPersonName2] = React.useState([]);
    const [gender, setgender] = React.useState([]);
    const [loading, setLoading] = React.useState(false)
    const [fileData, setFileData] = React.useState(null);
    const [tenetData, setTenetData] = React.useState([]);
    const [permanentData, setPermanentData] = React.useState(false);
    const [currentStep, setCurrentStep] = React.useState(0)
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(update_form),
    });
    React.useEffect(() => {
        getTenetVerifiedDetails();
        const user = JSON.parse(localStorage.getItem("user"))
        setValue("tenent_first_name", user?.tenent_first_name);
        setValue("tenent_last_name", user?.tenent_last_name);
        setValue("tenent_mobile_number", user?.tenent_mobile_number);
    }, [])
    const handleChange3 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === "string" ? value.split(",") : value
        );
        if (value == "PAN Card (PAN Card Number)") {
            setValue("id_name", "panCard")
        } else if (value == "Driving License") {
            setValue("id_name", "drivingLicence")
        } else if (value == "Passport (Passport Application File Number)") {
            setValue("id_name", "passport")
        } else if (value == "Voter ID Card (Voted ID or EPIC Number)") {
            setValue("id_name", "voterId")
        } else if (value == "Adhaar Card (Adhaar or VID Number)") {
            setValue("id_name", "aadhar")
        }

        //setValue("id_name", "panCard")
        console.log(value)
    };
    const handleChange4 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName2(
            typeof value === "string" ? value.split(",") : value
        );

        if (value == "Driving License") {
            setValue("address_verification_id", "drivingLicence")
        } else if (value == "Passport (Passport Application File Number)") {
            setValue("address_verification_id", "passport")
        } else if (value == "Voter ID Card (Voted ID or EPIC Number)") {
            setValue("address_verification_id", "voterId")
        }

        //setValue("id_name", "panCard")
    };

    const getTenetVerifiedDetails = () => {
        axios.get(`${LIVEURL}tenant/`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                if (res.data.id_number) {
                    navigate("/verification-result");
                    return;
                }
            } else {
                setLoading(false)

            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                toast.error(e?.response?.data?.message)
            }
            setLoading(false)
        })
    }
    //------------------- tenantForm schema-------------->
    const verifyIds = (data) => {
        setLoading(true)
        axios.put(`${LIVEURL}tenant/update`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                localStorage.getItem("isAddress", true)
                const url = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=CD61393B&redirect_uri=https://score10.in/return-url&response_type=code&state=ap"
                const link = document.createElement("a")
                link.href = url
                document.body.appendChild(link)
                link.click()
                // navigate("/verification-result")
            } else {
                setLoading(false)
                alert(res?.response?.data?.message);
            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                alert("Not valid Id number")
            }
            setLoading(false)
        })
    }

    const apiCall = () =>{
        let formData = getValues();
        console.log(formData,"formData");
        const data = {
            tenant_address:formData?.current_street_address+','+formData?.current_address_line+','+formData?.current_city+','+formData?.current_state+','+formData?.current_postal_code,
            total_years_in_current_address: formData?.total_years_in_current_address,
            total_months_in_current_address: formData?.total_months_in_current_address,
            id_name: formData?.id_name,
            id_number: formData?.id_number,
            dob: formData?.tenent_dob,
            more_tenents: tenetData,
            address_verification_id: formData?.id_name == "aadhar" ? null : "aadhar",
            address_verification_number: formData?.id_name == "aadhar" ? null : "aadhar",
        };
        if (formData?.id_name == "aadhar") {
            localStorage.setItem("aadharVerify", JSON.stringify(data));
            const url = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=CD61393B&redirect_uri=https://score10.in/return-url&response_type=code&state=ap"
            const link = document.createElement("a")
            link.href = url
            document.body.appendChild(link)
            link.click()
            return;
        }
        verifyIds(data);
    }
    const roleContext = React.useContext(UserRoleContext);
    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                        <Button
                            className="ml-10 app-register-form-button"
                            variant="outlined" color="error"
                            onClick={() => {
                                localStorage.clear()
                                roleContext.updateContext({ isLoggedIn: false });
                                navigate("/login")
                            }}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Please Fill the form to continue</p>
                        </div>
                        <div className="app-new-auth-page-card app-new-auth-page-card-tenant">
                            
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={currentStep} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>

                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                                {currentStep == 0 && 
                                <ul className="app-login-form-field-group">
                                    {/* <div className="app-user-current-location">
                                        <Button
                                            variant="contained"
                                            onClick={() => { }}
                                        >
                                        <img src="/images/gps.svg"/>
                                        Use Current location
                                        </Button>
                                    </div>
                                    <span className="font-bold text-center font-weight-bold mt-3 mb-4">(OR)</span>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <GooglePlacesAutocomplete
                                                selectProps={{
                                                    className: "react-select-container search-field",
                                                    classNamePrefix: "inner",
                                                    //  locationInput,
                                                    onChange: (e) => {
                                                        console.log(e)
                                                    },
                                                    placeholder: "search for location in google"
                                                }}
                                                apiKey="AIzaSyCZ0OAV7Q-p6TbHLGPZGJY26QSJtj0dt50"
                                            />

                                        </Grid>
                                    </Grid>
                                    <span className="font-bold text-center font-weight-bold mt-40 mb-4">OR (Enter Manually)</span> */}
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Street address"
                                                                className="app-login-form-field"
                                                                {...register("current_street_address")}
                                                            />
                                                            
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Address line 2"
                                                                className="app-login-form-field"
                                                                {...register("current_address_line")}
                                                            />
                                                            
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="City"
                                                                className="app-login-form-field"
                                                                {...register("current_city")}
                                                            />
                                                            
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="State"
                                                                className="app-login-form-field"
                                                                {...register("current_state")}
                                                            />
                                                            
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                min="1"
                                                                placeholder="Zip/postal code"
                                                                className="app-login-form-field"
                                                                {...register("current_postal_code")}
                                                            />
                                                            
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"text"}
                                                                placeholder="Country"
                                                                className="app-login-form-field"
                                                                value="India"
                                                                {...register("current_postal_country")}
                                                            />
                                                           
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>


                                                <div className="app-register-form-field">
                                                    <label className="app-register-form-field-label" style={{ marginTop: "30px" }}>
                                                        Choose Month & Year from since you are staying here
                                                    </label>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                min="1"
                                                                placeholder="Total months in current address"
                                                                className="app-login-form-field"
                                                                {...register("total_years_in_current_address")}
                                                            />
                                                            {errors?.total_years_in_current_address && (
                                                                <span style={{ color: "red" }}>
                                                                    {errors?.total_years_in_current_address?.message}
                                                                </span>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <input
                                                                type={"number"}
                                                                onkeydown="return event.keyCode !== 69"
                                                                min="1"
                                                                placeholder="Total years in current address"
                                                                className="app-login-form-field"
                                                                {...register("total_months_in_current_address")}
                                                            />
                                                            {errors?.total_months_in_current_address && (
                                                                <span style={{ color: "red" }}>
                                                                    {errors?.total_months_in_current_address?.message}
                                                                </span>
                                                            )}
                                                        </Grid>

                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-auth-button justify-content-end">
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => { setCurrentStep(currentStep+1) }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>
                                }


                                {currentStep == 1 && 
                                <ul className="app-login-form-field-group" style={{ marginTop: "50px" }}>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2}>
                                                        <Grid item md={12} lg={6}>
                                                            <div className="app-register-form-field">
                                                                <label className="app-register-form-field-label">
                                                                    Choose ID Card Type for Verification
                                                                </label>
                                                                <FormControl className="w-100" sx={{}}>
                                                                    <Select
                                                                        displayEmpty
                                                                        className="app-custom-select"
                                                                        value={personName}
                                                                        onChange={handleChange3}
                                                                        IconComponent={DownChevron}
                                                                        input={<OutlinedInput />}
                                                                        renderValue={(selected) => {
                                                                            if (selected.length === 0) {
                                                                                return (
                                                                                    <span className="app-custom-select-placeholder">
                                                                                        Select ID
                                                                                    </span>
                                                                                );
                                                                            }

                                                                            return selected.join(", ");
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                        }}
                                                                    >
                                                                        <MenuItem disabled value="">
                                                                            Select ID
                                                                        </MenuItem>
                                                                        {files.map((file) => (
                                                                            <MenuItem key={file} value={file}>
                                                                                {file}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {errors?.id_name && (
                                                                        <span style={{ color: "red" }}>
                                                                            {errors?.id_name?.message}
                                                                        </span>
                                                                    )}
                                                                </FormControl>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={12} lg={6}>
                                                            <div className="app-register-form-field">
                                                                <label className="app-register-form-field-label">
                                                                    ID Number
                                                                </label>
                                                                <input
                                                                    type={"text"}
                                                                    placeholder="enter id number"
                                                                    className="app-login-form-field"
                                                                    {...register("id_number")}
                                                                />
                                                                {errors?.id_number && (
                                                                    <span style={{ color: "red" }}>
                                                                        {errors?.id_number?.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>

                                    {
                                        personName != "" && personName != "Adhaar Card (Adhaar or VID Number)" && <li>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="app-register-form-field">
                                                        <Grid container spacing={2}>
                                                            <Grid item md={12} lg={6}>
                                                                <div className="app-register-form-field">
                                                                    <label className="app-register-form-field-label">
                                                                        Choose ID Card Type for Address Verification
                                                                    </label>
                                                                    <FormControl className="w-100" sx={{}}>
                                                                        <Select
                                                                            displayEmpty
                                                                            className="app-custom-select"
                                                                            value={personName2}
                                                                            onChange={handleChange4}
                                                                            IconComponent={DownChevron}
                                                                            input={<OutlinedInput />}
                                                                            renderValue={(selected) => {
                                                                                if (selected.length === 0) {
                                                                                    return (
                                                                                        <span className="app-custom-select-placeholder">
                                                                                            Select ID
                                                                                        </span>
                                                                                    );
                                                                                }

                                                                                return selected.join(", ");
                                                                            }}
                                                                            MenuProps={MenuProps}
                                                                            inputProps={{
                                                                                "aria-label": "Without label",
                                                                            }}
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                Select ID
                                                                            </MenuItem>
                                                                            <MenuItem key={"Adhaar Card (Adhaar or VID Number)"} value={"Adhaar Card (Adhaar or VID Number)"}>
                                                                                {"Adhaar Card (Adhaar or VID Number)"}
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </Grid>
                                                            <Grid item md={12} lg={6}>
                                                                <div className="app-register-form-field">
                                                                    <label className="app-register-form-field-label">
                                                                        ID Number
                                                                    </label>
                                                                    <input
                                                                        type={"text"}
                                                                        placeholder="enter address verification number"
                                                                        className="app-login-form-field"
                                                                        {...register("address_verification_number")}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </li>
                                    }
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>

                                                <div className="app-auth-button justify-content-end">
                                                    <Button
                                                        variant="contained"
                                                        style={{ marginRight: "20px" }}
                                                        onClick={() => { setCurrentStep(currentStep - 1) }}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {apiCall();}}
                                                    >
                                                        Verify now
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>}

                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
