import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Modal from '@mui/material/Modal';
import axios from "axios";
import { LIVEURLNEW, LIVEURLNEW2 } from "../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import NonRegisteredLandlords from "../components/nonRegisteredLandlords";
import RegisteredLandlords from "../components/registeredLandlords";
import { Box } from "@mui/material";
import Label from "../components/label";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius:1
};
export default function RWADashboard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getunRegisteredLandlords = () => {
    setLoading(true)
    axios.get(`${LIVEURLNEW2}RWA/rwaLandlordStatus`, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }).then((res) => {
        if (res.status === 200) {
            setDashboardData(res?.data)
        }
        setLoading(false)
    }).catch((e) => {
        setLoading(false)
        if (e.response.status == 404) {
            toast.error(e.response?.data?.message)
        }
        else if (e.response.status == 401) {
            toast.error(e.response?.data?.message)
        }})
  };

  const getVerificationDetails = (row) => {
    setLoading(true)
    axios.get(`${LIVEURLNEW2}requestStatus/landlordTenantStatus/view?landlordTenantProperty=${row?._id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }).then((res) => {
        if (res.status === 200) {
          setSelectedOption(1)
          setSelectedData({...res?.data,...row})
          handleOpen()
        }
        setLoading(false)
    }).catch((e) => {
        setLoading(false)
        if (e.response.status == 404) {
            toast.error(e.response?.data?.message)
        }
        else if (e.response.status == 401) {
            toast.error(e.response?.data?.message)
        }})
  };

  const getRegisteredLandlords = () => {
    setLoading(true)
    const rwaId = JSON.parse(localStorage.getItem("user"))?._id;
    axios
      .get(`${LIVEURLNEW2}landlordTenantProperty/rwaProperties?rwa=${rwaId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setDashboardData(res?.data);
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        if (e.response.status == 404) {
          toast.error(e.response?.data?.message);
        } else if (e.response.status == 401) {
          toast.error(e.response?.data?.message);
        } else if (e.response.status == 400) {
          toast.error(e.response?.data?.message);
        } else {
          toast.error("something went wrong");
        }
      });
  };

  const resendLink = async (data) => {
    const { status } = await axios.get(
      `${LIVEURLNEW2}RWA/resendLinkToLandlord/${data?._id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (status == 201 || status == 200) {
      let tempData = dashboardData;
      let index = dashboardData?.findIndex((item) => item?._id == data?._id);
      tempData[index] = {
        ...tempData[index],
        disable: true,
      };
      setDashboardData([...tempData]);
      toast.success("Link Reshared Successfully");
      return;
    }
    toast.error("Something went wrong");
  };

  useEffect(() => {
    if(selectedTab == 1) getunRegisteredLandlords();
    if(selectedTab == 2) getRegisteredLandlords();
  }, [selectedTab]);


  const dowloadPDF = (pdfUrl) => {
    if(!pdfUrl) {
      alert("Report not available yet")
      return
    }    
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'filename.pdf');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="app-new-auth-header">
            <img
              className="app-new-auth-header-icon"
              src="/images/landing-page/logo3.png"
            />
            <Button
              style={{ marginTop: 10 }}
              onClick={() => {
                localStorage.clear();
                navigate("/rwaLogin");
              }}
              variant="contained"
            >
              Logout
            </Button>
          </div>
        </div>
      </header>
      <div className="app-new-auth-page login-bg-image">
        <div className="container h-100" style={{ maxWidth: "90%" }}>
          <div
            className="app-new-auth-page-wrapper"
            style={{ maxWidth: "100%" }}
          >
            <div className="app-new-auth-page-heading">
              <h1>
                Welcome to {JSON.parse(localStorage.getItem("user"))?.name}
              </h1>
              <p>RWA Dashboard</p>
            </div>
            <div
              className="app-new-auth-page-card app-new-auth-page-card-tenant"
              style={{ maxWidth: "100%", minWidth: "100%"}}
            >
             
                <ul className="app-login-form-field-group">
                    <li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{borderBottom:selectedTab == 1  ? '2px solid #2e76d2' : null, marginRight: 30}}>
                            <span
                              onClick={() => {
                                setSelectedTab(1);
                              }}
                             
                              style={{ cursor:'pointer',fontWeight:selectedTab == 1  ?'bold' : 400,fontSize: selectedTab == 1  ? 16 : 14}}
                            >
                              Non Registered Landlords
                            </span>
                            
                          </div>
                          <div style={{borderBottom:selectedTab == 2  ? '2px solid #2e76d2' : null, marginRight: 10}}>
                            <span
                              onClick={() => {
                                setSelectedTab(2);
                              }}
                              style={{ cursor:'pointer',fontWeight: selectedTab == 2  ?'bold' : 400,fontSize: selectedTab == 2  ? 16 : 14}}
                             
                            >
                              Registered Landlords
                            </span>
                          </div>
                        </div>

                        <Button
                          onClick={() => {
                            navigate("/sendLinkToLandlord");
                          }}
                          variant="contained"
                        >
                          {"Request Landlord"}
                        </Button>
                      </div>
                      {selectedTab == 1 ? 
                     <NonRegisteredLandlords dashboardData={dashboardData} loading={loading} resendLink={resendLink}/>
                      
                      
                      :<RegisteredLandlords getVerificationDetails={getVerificationDetails} dashboardData={dashboardData} loading={loading} dowloadPDF={dowloadPDF}/>}
                    </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 ,backgroundColor:'white'}}>
        <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{borderBottom:selectedOption == 1  ? '2px solid #2e76d2' : null, marginRight: 10}}>
                            <span
                              onClick={() => {
                                setSelectedOption(1);
                              }}
                             
                              style={{ cursor:'pointer',fontWeight:selectedOption == 1  ?'bold' : 400,fontSize: selectedOption == 1  ? 16 : 14}}
                            >
                              Landlord Details
                            </span>
                            
                          </div>
                          <div style={{borderBottom:selectedOption == 2  ? '2px solid #2e76d2' : null, marginRight: 10}}>
                            <span
                              onClick={() => {
                                setSelectedOption(2);
                              }}
                              style={{ cursor:'pointer',fontWeight: selectedOption == 2  ?'bold' : 400,fontSize: selectedOption == 2  ? 16 : 14}}
                             
                            >
                              Tenant Details
                            </span>
                          </div>
                          <div style={{borderBottom:selectedOption == 3  ? '2px solid #2e76d2' : null, marginRight: 10}}>
                            <span
                              onClick={() => {
                                setSelectedOption(3);
                              }}
                              style={{ cursor:'pointer',fontWeight: selectedOption == 3  ?'bold' : 400,fontSize: selectedOption == 3  ? 16 : 14}}
                             
                            >
                              Verification Details
                            </span>
                          </div>
                        </div>

                        
        </div>

        {selectedOption == 1 && <div className="mt-10" style={{marginTop:30}}>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>First name</div>
            <div style={{fontSize:14}}>: {selectedData?.landlord?.first_name}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Last name</div>
            <div style={{fontSize:14}}>: {selectedData?.landlord?.last_name}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Mobile</div>
            <div style={{fontSize:14}}>: {selectedData?.landlord?.mobile}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Email</div>
            <div style={{fontSize:14}}>: {selectedData?.landlord?.email}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Flat No</div>
            <div style={{fontSize:14}}>: {selectedData?.property?.flat_no}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Block No</div>
            <div style={{fontSize:14}}>: {selectedData?.property?.block_no}</div>
          </div>
        </div>}
        {selectedOption == 2 && <div className="mt-10" style={{marginTop:30}}>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>First name</div>
            <div style={{fontSize:14}}>: {selectedData?.tenant?.first_name}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Last name</div>
            <div style={{fontSize:14}}>: {selectedData?.tenant?.last_name}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Mobile</div>
            <div style={{fontSize:14}}>: {selectedData?.tenant?.mobile}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Email</div>
            <div style={{fontSize:14}}>: {selectedData?.tenant?.email}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Tenant type</div>
            <div style={{fontSize:14}}>: {selectedData?.tenant_type}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:100,fontWeight:'bold',fontSize:14}}>Is Verified</div>
            <div style={{fontSize:14}}>: <Label color={(!selectedData?.tenant?.generatedReportUrl && 'error') || 'success'}>{selectedData?.tenant?.generatedReportUrl ? 'Yes' : 'No'}</Label></div>
          </div>
          <Button
                  disabled={false}
                  style={{ marginTop: 20}}
                  onClick={() => {
                    dowloadPDF(selectedData?.tenant?.generatedReportUrl?.Location)
                  }}
                  variant="contained"
                >
                  Tenant Report
                </Button>
        </div>}
        {selectedOption == 3 && <div className="mt-10" style={{marginTop:30}}>
          <div className="d-flex mt-10">
            <div style={{width:120,fontWeight:'bold',fontSize:14}}>Opted Services</div>
            <div style={{fontSize:14}}>: {selectedData?.optedServices?.map(service => service.name).join(', ')}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:120,fontWeight:'bold',fontSize:14}}>Verified Services</div>
            <div style={{fontSize:14}}>: {selectedData?.optedServices?.map(service => service.name).join(', ')}</div>
          </div>
          <div className="d-flex mt-10">
            <div style={{width:120,fontWeight:'bold',fontSize:14}}>Amount paid</div>
            <div style={{fontSize:14}}>: ₹{selectedData?.paymentHistory?.amount}</div>
          </div>
        </div>}
        </Box>
      </Modal>
    </>
  );
}
