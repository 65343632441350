import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW } from '../utils/constant';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PropertyList() {
    const { id } = useParams();
    const [propertyList, setPropertyList] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null);

    const getProperties = async () => {
        const { data, status } = await axios.get(`${LIVEURLNEW}propertyList/${id}`);
        if (status == 200) {
            data?.map((item) => {
                setPropertyList((prev) => {
                    return [...prev, { value: item?._id, label: item?.building_name }]
                });
            })
            return;
        }
    }

    useEffect(() => {
        getProperties();
    }, [])

    const openWhatsApp = () => {
        if (selectedProperty == null) {
            toast.error("Please select the property");
            return
        }
        window.location.href = `whatsapp://send?phone=+919505895078&text=${encodeURIComponent(selectedProperty?.value)}`;
    };

    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>Please select the property</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            <ul className="app-login-form-field-group">
                                <p>Select the property</p>
                                <Select onChange={(e) => { setSelectedProperty(e) }} options={propertyList} />
                            </ul>

                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="app-auth-button justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={openWhatsApp}

                                        >
                                            Done
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
