import React from 'react'
import "../styles/pages/landing-page.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
export default function privacyPolicy() {
  return (
    <div className="app-landing-page">
      <div className="app-landing-page-header">
        <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/">
            <img className="app-landing-page-logo" src="/images/landing-page/logo3.png"/>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="/#services">Services</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="/#about">About</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="/#contactus">Contact Us</a>
                </li>
                <li>
                  <Link to="/login">
                  <Button type="button" className="app-home-cta" variant="contained">Get Started</Button>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="policy-statement">
          <div className="container">
            <h1>Privacy Policy</h1>
            <p>Your privacy is important to us and we do take it seriously, so Score 10 has created the following Privacy Policy to let you know what information we collect when you visit our Site, why we collect it and how it is used. This Privacy Policy explains the data collection and use practices of our website, www.score10.in (“Site”); it does NOT apply to other online or offline Score 10 sites, products or services. The terms “you,” “your, ” and “yours” refer to the Customer/User, Individual or Individual representing an Organization, using our Site. The terms “Score 10,”Score10,” “we,” “us,” “our,” and “Company” refer to Score 10 and its subsidiaries and affiliates. This Privacy Policy is governed by our Terms and Conditions. By using this site, you consent to the data practices prescribed in this statement. We may periodically make changes to this Privacy Policy and the updated version will be made available to you on this page <a href="www.score10.in/privacy-policy">www.score10.in/privacy-policy</a>. It is your responsibility to review this Privacy Policy frequently and remain informed about any changes to it, so we encourage you to visit this page often.</p>
            <h1>User data collection, use and retention</h1>
            <p>The below section(s) describes what User data is collected by the Site, why it is collected, retention policies pertaining to such data and how this is used. User data doesn’t include Content submitted by the User to the Site.</p>
            <h1>Collection</h1>
            <p><b>PERSONALLY IDENTIFIABLE INFORMATION FURNISHED BY THE USER:</b> Personally Identifiable Information (“PII”) furnished by the User during signing up on the Site or subsequently, including but not limited to, Email address, Full Name, Mobile number, Physical address, Aadhaar ID Number is collected by the Company while a User signs up on the Site or thereafter
            </p>
            <p><b>AUTOMATIC COLLECTION OF DATA:</b> The Company also automatically receives and records information on our server logs from your browser, including but not limited to, your IP address, cookie information and the page you requested. You can choose not to provide us with certain information, but then you might not be able to take advantage of many of our features.</p>
            <h1>Use</h1>
            <p>The Company uses your PII to contact you for various Service-related activities and related information that is collected from the site. Automatically collected data is used to better your experience on the Site while using the Services. </p>
            <p>This data is also used for data analytics by the Company. The Company does not use the stored data and information collected in the provision of the KYC Solution for any other transactions or information sharing.</p>
            <h3>DATA SHARING OR DISCLOSURE </h3>
            <p>Personal Information about our users is an integral part of our business. We neither rent nor sell your Personal Information to anyone. We may share your Personal Information only with your consent to our customers in respect of whom you have chosen, or in respect of whom you have acted upon, to use the Services of Score 10 or to business partners and affiliates as described below:</p>
            <h3>AGENTS </h3>
            <p>We may employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. Unless we tell you differently, the Company’s agents do not have any right to use Personal Information we share with them beyond what is necessary to assist us. You hereby consent to our sharing of Personal Information for the above purpose.</p>
            <h3>BUSINESS PARTNERS AND CUSTOMERS </h3>
            <p>In some cases, we may enter into business partnership arrangements wherein we will share your Personal Information with such a partner only to enable such partner to integrate its technology with the Company’s technology and render its own services bundled with our services. Unless you are previously notified, all business partners will treat your information in the same manner as the Company under this policy. However, the Company is not responsible for monitoring or enforcing each partner and/or customer’s handling of your Personal Information. </p>
            <h3>BUSINESS TRANSFERS </h3>
            <p>In some cases, we may choose to buy or sell assets. In these types of transactions, user/customer information is typically one of the business assets that is transferred. Moreover, if the Company or substantially all of its assets were acquired, user/customer information would be one of the assets that are transferred or acquired by a Third-party. You acknowledge that such transfers may occur and that any acquirer of the Company may continue to use your Personal Information as set forth in this policy. </p>
            <h3>PROTECTION OF THE COMPANY AND OTHERS </h3>
            <p>We may release Personal Information when we believe in good faith that release is necessary to comply with any law or regulation; enforce or apply our terms of use and other agreements; or protect the rights, property, or safety of the Company, our employees, our customers, or others. </p>
            <h3>COMPLIANCE WITH LAW ENFORCEMENT AND JUDICIAL AUTHORITIES </h3>
            <p>We shall be bound to disclose Personal Information in response to a request/summons from a law enforcement authority; a court of law; or a quasi-judicial body. </p>
            <h3>INTERNATIONAL TRANSFERS </h3>
            <p>Personal Information collected on this web site may be stored and processed in India or any other country in which the Company or its affiliates, subsidiaries or agents maintain facilities, and by using this Site, you consent to any such transfer of information outside of your country. </p>
            <h3>CHILDREN </h3>
            <p>This Site is not intended for or directed to persons under the age of 15. Any person who provides their information to the Company through the Account Login page for new customers, Signup Page, or any other part of the Site represents to the company that they are 15 years of age or older. </p>
            <h3>COOKIES</h3>
            <p>Cookies are small text files stored by your browser in your computer when you visit our Site. We use cookies to improve our Site and make it easier to use. Cookies permit us to recognize users and avoid repetitive requests for the same information. Cookies from our Site can not be read by other Sites. Most browsers will accept cookies until you change your browser settings to refuse them.</p>
            <h2>Retention</h2>
            <p>All User data collected by the Company on the Site is retained perpetually unless stated otherwise in a particular application flow or the contract  This is required for establishing an audit trail of Content submitted by the User on the Site. The User consents to such retention policy of the Company for being able to use the Site and Services.</p>
            <h2>Your Consent to this Privacy Policy</h2>
            <p>By using this Site, you agree to this Privacy Policy. This is our entire and exclusive Privacy Policy and it supersedes any earlier version. Our Terms and Conditions take precedence over any conflicting Privacy Policy provision. We may change this Privacy Policy by posting a new version of this Privacy Policy on this Site which it is your responsibility to review. </p>
            <h2>Legal Disclaimer </h2>
            <p>This Site operates “AS-IS” and “AS-AVAILABLE,” without liability of any kind. We are not responsible for events beyond our direct control. This Privacy Policy is governed by Indian laws, excluding conflicts of law principles. Any legal actions against us must be commenced in the courts of Hyderabad, India, and within one (1) year after the claim arose, or such action(s) will be barred. </p>
            <h2>Opting out </h2>
            <p>If you would like to cancel your subscription and/or unsubscribe from our email list, email us at <a href="mailto:care@score10.in">care@score10.in</a> and we will assist you. </p>
            <h2>Contacting the Company</h2>
            <p>If you believe that the Company has not adhered to this Privacy Policy, please contact us by email at <a href="mailto:legal@score10.in">legal@score10.in</a> and we will use commercially reasonable efforts to remedy the problem. We can also be contacted by mail at 20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana 500081</p>
          </div>
      </div>
        <div className="app-landing-footer">
            <div className=''>
              <div className="app-landing-footer-content">
              <div className="app-landing-footer-highlight">
              <img src="/images/landing-page/logo3.png"/>
              <div className="ml-3">
                <p>Score10 <span>TM</span> is a product of Beatroot Finserv Pvt Ltd.</p>
                <h6>CIN: 	U72500TG2022PTC161895</h6>
              </div>
              </div>
              <ul>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                <a href="#about">About</a>
                </li>
                <li>
                  <a href="#contactus">
                  Contact Us
                  </a>
                </li>
                <li>
                  <Link to="/terms-and-conditions">
                  Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/refund-policy">
                  Refund Policy
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                  Privacy Policy
                  </Link>
                </li>
              </ul>
              </div>
              <div className="copy-right-content">

              </div>
            </div>
        </div>
    </div>
  )
}
