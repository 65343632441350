import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW } from '../utils/constant';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function VerificationLink() {
    const { id , tenant} = useParams();
    const [propertyList, setPropertyList] = useState([]);
    const [identityDetails, setIdentityDetails] = useState(null);
    const [identityList, setIdentityList] = useState([])
    const [selectedIdentity, setSelectedIdentity] = useState(null);
    const [enternedId, setEnternedId] = useState(null)


    const getVerificationIds = async () => {
        const { data, status } = await axios.get(`${LIVEURLNEW}LandlordTenantProperty/${id}`);
        if (status == 200) {
            setPropertyList(data)
            data[0]?.ids_available?.map((item) => {
                setIdentityList(prev => [...prev, { value: item, label: item }])
            })
            console.log(data)
            return;
        }
    }

   

    useEffect(() => {
        getVerificationIds();
    }, [])

    const startVerification = async () => {
        if(enternedId == null){
            toast.error("Please enter ID");
            return
        }
        try{
            const payload = {
                "verification_id": selectedIdentity,
                "verification_name":  propertyList[0]?._id,
                "verification_id_number": enternedId,
                "tenant": tenant,
                "landlordTenantPropertyId" : id
            };
            localStorage?.setItem("payload",JSON.stringify({...payload,verification_id:"Aadhar","verification_name":  propertyList[1]?._id}))
            const { status } = await axios.post(`${LIVEURLNEW}reports/`, payload);
            if (status == 200 || status == 201) {
                localStorage.setItem("isWhatsapp",true)
                const url = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=CD61393B&redirect_uri=https://score10.in/return-url&response_type=code&state=ap"
                const link = document.createElement("a")
                link.href = url
                document.body.appendChild(link)
                link.click()
                return;
            } 
        }catch(e){
            console.log(e);
        }
        
    };

    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />
                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <div className="container h-100">
                    <div className="app-new-auth-page-wrapper">
                        <div className="app-new-auth-page-heading">
                            <h1>Welcome</h1>
                            <p>{identityDetails ? "Address verification" : "Identity verification"}</p>
                        </div>
                        <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                            <ul className="app-login-form-field-group">
                                <p>Select ID to verify</p>
                                <Select onChange={(e) => { setSelectedIdentity(e?.label) }} options={identityList} />
                                <p></p>
                                <p>Enter ID number</p>
                                <input
                                    type={"text"}
                                    placeholder="Id Number"
                                    // className="app-login-form-field"
                                    onChange={(e) => {
                                        setEnternedId(e.target.value)
                                    }}
                                />
                            </ul>

                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="app-auth-button justify-content-center">
                                        <Button
                                            variant="contained"
                                            onClick={startVerification}
                                        >
                                            Start Verification
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
