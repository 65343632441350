import * as yup from "yup";

export const loginSchema = yup
  .object({
    email: yup.string().email().required("It is a required field"),
   // login_type: yup.string().required("It is a required field"),
    password: yup.string().required("It is a required field"),
  })

export const registerSchema = yup
  .object({
    mobile: yup.string().required("It is a required field").min(10).max(10),
    first_name: yup.string().matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'Name can only contain Latin letters.'
      ).required("It is a required field"),
    last_name: yup.string().matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'Name can only contain Latin letters.'
      ).required("It is a required field"),
    email: yup.string().email("please enter valid email").required("It is a required field"),
    password: yup.string().min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol')
    
  
  })

  export const forgotPasswordSchema = yup
  .object({
    password:  yup.string().min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  })
  export const emailSchema = yup
  .object({
    email: yup.string().email().required("It is a required field"),
  })
export const tetentFormSchema = yup
  .object({
    "tenent_first_name": yup.string().matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'Name can only contain Latin letters.'
      ).required("It is a required field"),
    "tenent_email": yup.string().email("must be a valid email").required("It is a required field"),
    "tenent_address": yup.string().required("It is a required field"),
    "tenent_last_name": yup.string().matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
          'Name can only contain Latin letters.'
      ).required("It is a required field"),
    "tenent_mobile_number": yup.string().required("It is a required field"),
    "id_number": yup.string().required("It is a required field"),
    //  "id_name": yup.string().required("It is a required field"),
    //  "id_image": yup.string().required("It is a required field"),
    "tenent_dob": yup.date().max(new Date(Date.now() - 567648000000), "You must be at least 18 years").required("Please select date of birth.")
  })


export const update_form = yup.object({
  "tenent_email": yup.string().email("must be a valid email").required("It is a required field"),
  // "permanent_street_address": yup.string().required("It is a required field"),
  // "permanent_address_line": yup.string().required("It is a required field"),
  // "permanent_city": yup.string().required("It is a required field"),
  // "permanent_state": yup.string().required("It is a required field"),
  // "permanent_postal_code": yup.string().required("It is a required field"),
  // "permanent_postal_country": yup.string().required("It is a required field"),
  // "current_street_address": yup.string().required("It is a required field"),
  // "current_address_line": yup.string().required("It is a required field"),
  // "current_city": yup.string().required("It is a required field"),
  // "current_state": yup.string().required("It is a required field"),
  // "current_postal_code": yup.string().required("It is a required field"),
  // "current_postal_country": yup.string().required("It is a required field"),
  "total_years_in_current_address": yup.string().required("It is a required field"),
  "id_number": yup.string().required("It is a required field"),
  "id_name": yup.string().required("It is a required field"),
  // "tenent_dob": yup.date().max(new Date(Date.now() - 567648000000), "You must be at least 18 years").required("Please select date of birth."),
  // "tenent_father_last_name": yup.string().required("It is a required field"),
  // "tenent_father_first_name": yup.string().required("It is a required field"),
  "total_months_in_current_address": yup.string().required("It is a required field"),
})

