import * as React from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { forgotPasswordSchema } from '../../utils/validations';
import axios from 'axios';
import { LIVEURL } from '../../utils/constant';
import { useContext } from "react";
import { UserRoleContext } from "../../utils/userAuthorization";
import { toast } from 'react-toastify';

function ForgotPassword() {
    const { id } = useParams();
    const navigate = useNavigate();
    const roleContext = useContext(UserRoleContext);
    const location = useLocation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(forgotPasswordSchema),
    });
    const onSubmit = async (formData) => {
        if (formData.password != formData?.confirm_password) {
            alert("password and confirm password should be same")
            return
        }
        const payload = {
        id:id,
         password: formData.password
        }
        axios.post(location?.pathname.includes("/user") ? `${LIVEURL}tenant/update-password` :`${LIVEURL}user/update-password`, payload).then((res) => {
            if (res.status === 200) {
                toast.success("password changed successfully")
                navigate("/login")
            } 
        }).catch((e) =>{
            if(e.response.status == 400){
                toast.error(e.response?.data?.message)
            }
        });
    };

    
    return (
        <>
         <header>
            <div className="container">
                <div className="app-new-auth-header">
                    <img onClick={() => {
                        navigate("/")
                    }} className="app-new-auth-header-icon" src="/images/landing-page/logo3.png"/>
                    <Link to="/login">
                        <Button>
                            <img src="/images/sign-in-icon.svg"/>
                            Forgot Password
                        </Button>
                    </Link>
                </div>
            </div>
        </header>
        <div className="app-new-auth-page register-bg-image">
            <div className="container h-100">
                <div className="app-new-auth-page-wrapper">
                    <div className="app-new-auth-page-heading">
                        <h1>Welcome</h1>
                        <p>Create New Password</p>
                    </div>
                    <div className="app-new-auth-page-card">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <ul className="app-login-form-field-group">
                            <li>
                            <input type={'text'} placeholder="Password" className="app-login-form-field" {...register("password")} />
                            {errors?.first_name && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.first_name?.message}</span>}
                            </li>
                            <li>
                            <input type={'text'} placeholder="Confirm password" className="app-login-form-field" {...register("confirm_password")} />
                            {errors?.last_name && <span className="app-warning-content"><img src="/images/caution.svg"/>{errors?.last_name?.message}</span>}
                            </li>
                        </ul>
                        <div className="app-auth-button">
                            <Button type="submit" variant="contained">Change password</Button>
                        </div>
                        <p className="app-login-form-button-alt">I already have an account <Link to="/login">Login</Link></p>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ForgotPassword;