import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { LIVEURL } from '../utils/constant';
import { toast } from 'react-toastify';
import DeleteConfirmation from '../models/deleteConfirmation';
import { UserRoleContext } from "../utils/userAuthorization";

export default function LandLordsDashboard() {
    const roleContext = React.useContext(UserRoleContext);
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
    
    const navigate = useNavigate();
    const [tenant_list, setTenantList] = React.useState([]);
    const [loading, setLoading] = React.useState(false)

    const getTenetVerifiedDetails = () => {
        axios.get(`${LIVEURL}tenant/tenantByLandlord/`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setTenantList(res.data)
            } else {
                setLoading(false)

            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                toast.error(e?.response?.data?.message)
            }
            setLoading(false)
        })
    }

    React.useEffect(() => {
        getTenetVerifiedDetails();
    }, [])

    const deleteTenant = (id) => {
        axios.get(`${LIVEURL}tenant/delete/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                getTenetVerifiedDetails();
            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                toast.error(e?.response?.data?.message)
            }
        })
    }


    return (
        <>
            <header>
                <div className="container-fluid">
                    <div className="app-new-auth-header">
                        <img onClick={() => {
                            navigate("/")
                        }} className="app-new-auth-header-icon" src="/images/landing-page/logo3.png"/>
                        <div className="app-new-dashboard-header">
                            <Button
                                onClick={() => {
                                    navigate("/register-tenant")
                                }}
                                className="app-register-form-button"
                                variant="contained"
                            >
                                Register Tenants
                            </Button>
                            <Button
                                className="ml-10 app-register-form-button"
                                variant="outlined" color="error"
                                onClick={() => {
                                    localStorage.clear()
                                    roleContext.updateContext({ isLoggedIn: false });
                                    navigate("/login")
                                }}
                            >
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>
            </header>
            <div className="app-page-section">
                    <div className="app-table-wrapper">
                        {!tenant_list.length && !loading &&
                        <div className="app-no-tenant-wrapper">
                             <img  src="/images/no-tenants.svg"/>
                             <h3>No Tenants Found</h3>
                        </div>}
                {tenant_list.length > 0 && <TableContainer component={Paper}>

                    
                    <Table sx={{ minWidth: 650 }} className="app-custom-table" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Gender</TableCell>
                                <TableCell align="left">Date of birth</TableCell>
                                <TableCell align="left">Father name</TableCell>
                                <TableCell align="left">Mobile number</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Verified Id</TableCell>
                                <TableCell align="left">Verified Id number</TableCell>
                                <TableCell align="left">IsVerified</TableCell>
                                <TableCell align="left">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenant_list.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.tenent_first_name} {row.tenent_last_name}
                                    </TableCell>
                                    <TableCell align="left">{row.gender}</TableCell>
                                    <TableCell align="left">{row.dob}</TableCell>
                                    <TableCell align="left">{row.tenent_father_first_name} {row.tenent_father_last_name}</TableCell>
                                    <TableCell align="left">{row.tenent_mobile_number}</TableCell>
                                    <TableCell align="left">{row.tenent_email}</TableCell>
                                    <TableCell align="left">{row.id_name}</TableCell>
                                    <TableCell align="left">{row.id_number}</TableCell>
                                    <TableCell align="left">{row.id_number !=null ? "TRUE": "FALSE"}</TableCell>
                                    <TableCell align="left" style={{display:'flex'}}>
                                       {row.id_number !=null && <IconButton 
                                            onClick={() => {
                                                navigate(`/tenant/${tenant_list[index]._id}`)
                                            }}
                                            variant="contained"
                                        >
                                            <img className="button-icon-img" src="/images/view.svg"/>
                                        </IconButton>}
                                        {/* {row.id_number !=null && <IconButton 
                                        style={{marginLeft:'10px'}}
                                            onClick={() => {
                                                deleteTenant(row._id);
                                            }}
                                            variant="contained"
                                        >
                                            <img className="button-icon-img" src="/images/delete.svg"/>
                                        </IconButton>} */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </div>
            <DeleteConfirmation handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} setOpen={setOpen}/>

        </div>
        </>
    )
}
