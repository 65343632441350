import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DownChevron from "../icons/verify2.gif";
import { LIVEURL, LIVEURLNEW } from '../utils/constant';
export default function AadharReturnPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [tenant_details, setTenent_details] = React.useState(null);
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    console.log(query.get("code"))

    const getTenetVerifiedDetails = () => {
        axios.get(`${LIVEURL}tenant/`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setTenent_details(res.data)
                updateMoreTenants(res?.data?.more_tenents)
            } else {
                setLoading(false)

            }
        }).catch((e) => {
            if (e?.response?.status == 400) {
                toast.error(e?.response?.data?.message)
            }
            setLoading(false)
        })
    }
    const startVerification = async () => {
        console.log(
            JSON.parse(localStorage?.getItem("payload")),
            query.get("code")
        )
        const payload = {
            ...JSON.parse(localStorage?.getItem("payload")),
            "code": query.get("code")
        };
        const { status } = await axios.post(`${LIVEURLNEW}reports/`, payload);
        if (status == 200 || status == 201) {
            const { landlordTenantPropertyId } = JSON.parse(localStorage?.getItem("payload"))
            const { status: generateReportStatus } = await axios.post(`${LIVEURLNEW}generateReport/`, { landlordTenantPropertyId: landlordTenantPropertyId });
            if (generateReportStatus == 200) {
                toast.success("Report will be generated and sent to landlord in sometime")
                window.location.href = `whatsapp://send?phone=+919505895078`;
                return
            }
            toast.success("Verification successfull")
            window.location.href = `whatsapp://send?phone=+919505895078`;
            // navigate("/verification-result") 
            return;
        }
        //code=a54af581f31311a7d0863d68928468ce0526fc4d&state=ap
    };

    useEffect(() => {
        if (localStorage.getItem("isWhatsapp")) {
            startVerification()
            return
        }
        if (localStorage.getItem('aadharVerify') != null) {
            updateTenants();
            return
        }
        if (localStorage.getItem('isAddress') != null) {
            updateAadharDetails();
            return
        }
        getTenetVerifiedDetails();
    }, [])

    const updateTenants = () => {
        axios.put(`${LIVEURL}tenant/update/aadhar`, { ...JSON.parse(localStorage.getItem("aadharVerify")), code: query.get("code") }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                localStorage.removeItem("aadharVerify");
                navigate("/verification-result")
            } else {
                setLoading(false)

            }
        }).catch((e) => {
            if (e?.response?.status == 400) {

            }
            setLoading(false)
        })
    }

    const updateAadharDetails = () => {
        axios.put(`${LIVEURL}tenant/update_address_verification_details/`, { code: query.get("code") }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                localStorage.removeItem("isAddress");
                navigate("/verification-result")
            } else {
                setLoading(false)
            }
        }).catch((e) => {
            if (e?.response?.status == 400) {

            }
            setLoading(false)
        })
    }


    const updateMoreTenants = (tenants) => {
        let all_more_tenents = tenants;
        all_more_tenents[localStorage.getItem("aadharIndex")] = {
            ...all_more_tenents[localStorage.getItem("aadharIndex")],
            isVerified: true,
        }
        const data = {
            more_tenents: all_more_tenents,
            code: query.get("code"),
            index: localStorage.getItem("aadharIndex")
        };
        axios.put(`${LIVEURL}tenant/update_more_tenants/aadhar`, data, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                localStorage.removeItem("aadharIndex");
                navigate("/verification-result")
            } else {
                setLoading(false)

            }
        }).catch((e) => {
            if (e?.response?.status == 400) {

            }
            setLoading(false)
        })
    }

    return (
        <div style={{ display: "flex", justifyContent: 'center', flexDirection: "column", alignItems: "center", width: "100%", height: "100%" }}>
            <img src={DownChevron} />
            <span style={{
                fontSize: '18px',
                position: 'absolute',
                top: '59%',
                fontWeight: 600
            }}>Your Aadhar Is Getting Verified by UIDAI. Please Have Patience</span>
        </div>
    );
}
