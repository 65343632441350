import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from 'react-select';
import OutlinedInput from "@mui/material/OutlinedInput";
import DownChevron from "../icons/DownChevron";
import axios from 'axios';
import { LIVEURLNEW, LIVEURLNEW2 } from '../utils/constant';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import * as yup from "yup";
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PreviewRentalAgreement() {
    const { id } = useParams();
    const [shortLinkDetails, setShortLinkDetails] = useState(null);
    const location = useLocation();
    const { details, paymentDetails } = location?.state ?? {}
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tenantAdded, setTenantAdded] = useState(false)

    const validationSchema = yup
        .object({
            lease_start_date: yup.string().required("It is a required field"),
            lease_term: yup.string().required("It is a required field"),
            meter_reading: yup.string().required("It is a required field"),
        })

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const createRentalAgreement = async () => {
        try {
            setLoading(true)
            const { data: shortLinkUrl, status: shortStatus } = await axios.get(`${LIVEURLNEW}getShortLinkDetails/${id}`)
            setShortLinkDetails(shortLinkUrl)
            if (shortStatus == 200) {
                let formData = getValues();
                const payload = {
                    "LandlordTenantProperty": details?._id,
                    "tenant": details?.tenant?._id,
                    "landlord": details?.landlord?._id,
                    "property": details?.property?._id,
                    "leaseTerm": formData?.lease_term?.toString(),
                    "leaseStartDate": formData?.lease_start_date?.toString(),
                    "annexure_1": formData?.annexure_1 ?? '',
                    "annexure_2": formData?.annexure_2 ?? '',
                    "annexure_3": formData?.annexure_3 ?? '',
                    "paymentId": paymentDetails?._id,
                    templateValues: {
                        agreement_state: "Hyderabad,Telangana",
                        agreement_date: moment().format("DD MMM YYYY"),
                        landlord_name: details?.landlord?.first_name,
                        landlord_address: "Miyapur allwyn colony",
                        tenant_name: details?.tenant?.first_name,
                        tenant_address: details?.tenant?.address,
                        property_address: details?.property?.address + ',' + details?.property?.city + ',' + details?.property?.state + ',' + details?.property?.pincode,
                        property_type: details?.property?.property_type,
                        no_of_bedooms: details?.property?.no_of_bedooms ?? '0',
                        no_of_bathooms: details?.property?.no_of_bathooms ?? '0',
                        no_of_carparks: details?.property?.no_of_carparks ?? '0',
                        square_feet: details?.property?.square_feet ?? '0',
                        lease_term: formData?.lease_term?.toString(),
                        lease_start_date: formData?.lease_start_date?.toString(),
                        notice_period: details?.notice_period,
                        rent_day: details?.repayment_date,
                        rental_deposit: details?.security_deposit,
                        rent_amount: details?.rent_amount,
                        meter_reading: formData?.meter_reading ?? '0',
                        annexure_1: formData?.annexure_1 ?? '',
                        annexure_2: formData?.annexure_2 ?? '',
                        annexure_3: formData?.annexure_3 ?? '',
                        tenant_city_state: "Hyderabad,Telangana",
                        landlord_city_state: "Hyderabad,Telangana",
                    }
                }
                const { data, status } = await axios.post(`${LIVEURLNEW2}rentalAgreement/`, payload, { headers: { Authorization: `Bearer ${shortLinkUrl?.token}` } })
                if (status == 200) {
                    setTenantAdded(true)
                    openWhatsApp();
                }
                setLoading(false)
            }
        } catch (e) {
            if (e?.response?.data?.message) {
                toast?.error(e?.response?.data?.message);
            }
            setLoading(false)
        }

    }






    const openWhatsApp = () => {
        // if (selectedProperty == null) {
        //     toast.error("Please select the property");
        //     return
        // }
        window.location.href = `whatsapp://send?phone=+919505895078`;
    };

    return (
        <>
            <header>
                <div className="container">
                    <div className="app-new-auth-header">
                        <img className="app-new-auth-header-icon" src="/images/landing-page/logo3.png" />

                    </div>
                </div>
            </header>
            <div className="app-new-auth-page login-bg-image">
                <form onSubmit={handleSubmit(createRentalAgreement)}>
                    <div className="container h-100">
                        <div className="app-new-auth-page-wrapper">
                            <div className="app-new-auth-page-heading">
                                <h1>Welcome</h1>
                                <p>Rental Agreement Details</p>
                            </div>
                            <div className='app-new-auth-page-card app-new-auth-page-card-tenant'>
                                {error ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                    <span className='font-weight-bold'>Your link got expired</span>
                                </div> : tenantAdded ? <div className='d-flex justify-content-center align-items-center flex-column'>
                                    <img className="app-new-auth-header-icon" style={{ marginBottom: 50, width: 150, height: 150 }} src="/images/linkbreak.gif" />
                                    <span className='font-weight-bold'>Rental agreement created successfully</span>
                                    <span className='font-weight-bold'>Both tenant and you will receive link via SMS to sign the agreement digitally, Once you sign that both parties will get a copy of it.</span>
                                </div> : <ul className="app-login-form-field-group" style={{ justifyContent: 'center', display: 'flex' }}>
                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={6}>
                                                            <span className='font-weight-bold d-flex justify-content-start' style={{ marginTop: 15, marginBottom: 10 }}>Landlord details</span>
                                                            <div className='d-flex align-items-center' >
                                                                <img src="/images/default-user.png" style={{ height: '75px', width: '75px', borderRadius: 50, marginRight: 15 }} />
                                                                <div className='d-flex flex-column'>
                                                                    <span className='font-weight-bold'>{details?.landlord?.first_name} {details?.landlord?.last_name}</span>
                                                                    <span>{details?.landlord?.mobile}</span>
                                                                    <span className='fs-14'> {details?.landlord?.email}</span>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <span className='font-weight-bold d-flex justify-content-start' style={{ marginTop: 15, marginBottom: 10 }}>Tenant details</span>
                                                            <div className='d-flex align-items-center' >
                                                                <img src="/images/default-user.png" style={{ height: '75px', width: '75px', borderRadius: 50, marginRight: 15 }} />
                                                                <div className='d-flex flex-column'>
                                                                    <span className='font-weight-bold'>{details?.tenant?.first_name} {details?.tenant?.last_name}</span>
                                                                    <span>{details?.tenant?.mobile}</span>
                                                                    <span className='fs-14'> {details?.tenant?.email}</span>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                            <span className='font-weight-bold d-flex justify-content-start' style={{ marginTop: 15, marginBottom: 10 }}>Property details</span>
                                                            <div className='d-flex align-items-center' >
                                                                <img src="/images/defaultProperty.jpg" style={{ height: '100px', width: '100px' }} />
                                                                <div className='d-flex flex-column'>
                                                                    <span className='font-weight-bold'>{details?.property?.building_name}</span>
                                                                    <span>{details?.property?.address}</span>
                                                                    <span className='fs-14'> Face : {details?.property?.property_face}</span>
                                                                    <span className='fs-14'> Status : {details?.property?.property_status}</span>
                                                                </div>
                                                            </div>
                                                        </Grid>


                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>

                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={12}>
                                                            <span className='font-weight-bold d-flex justify-content-center' style={{ marginTop: 15, marginBottom: 10 }}>Agreement Details</span>
                                                            <div className='d-flex align-items-center justify-content-center' >
                                                                <div className='d-flex flex-column'>
                                                                    {/* <span className='font-weight-bold'>{details?.property?.building_name}</span> */}
                                                                    <span>Rent amount  : {details?.rent_amount}</span>
                                                                    <span className='fs-14'> Rent payment day : {details?.repayment_date}</span>
                                                                    <span className='fs-14'> Notice period : {details?.notice_period} months</span>
                                                                    <span className='fs-14'> Security deposit : {details?.security_deposit}</span>

                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>



                                    <li>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div className="app-register-form-field">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} lg={12}>
                                                            <span className='font-weight-bold d-flex justify-content-center' style={{ marginTop: 15, marginBottom: 10 }}>Please enter few details</span>
                                                            <div className="app-register-form-field">
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <input
                                                                            type={"number"}
                                                                            placeholder="Lease term in years"
                                                                            className="app-login-form-field"
                                                                            {...register("lease_term")}
                                                                        />
                                                                        {errors?.lease_term && <span className="app-warning-content">{errors?.lease_term?.message}</span>}
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <input
                                                                            type={"date"}
                                                                            placeholder="Lease start date"
                                                                            className="app-login-form-field"
                                                                            {...register("lease_start_date")}
                                                                        />
                                                                        {errors?.lease_start_date && <span className="app-warning-content">{errors?.lease_start_date?.message}</span>}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <input
                                                                            type={"number"}
                                                                            placeholder="meter reading"
                                                                            className="app-login-form-field"
                                                                            {...register("meter_reading")}
                                                                        />
                                                                        {errors?.meter_reading && <span className="app-warning-content">{errors?.meter_reading?.message}</span>}
                                                                    </Grid>

                                                                </Grid>
                                                                <span className='d-flex justify-content-center font-weight-bold' style={{ marginTop: 20 }}>Please add extra rules if you have any</span>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <input
                                                                            type={"text"}
                                                                            placeholder="Rule 1"
                                                                            className="app-login-form-field"
                                                                            {...register("annexure_1")}
                                                                        />
                                                                        {/* {errors?.first_name && <span className="app-warning-content">{errors?.first_name?.message}</span>} */}
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <input
                                                                            type={"text"}
                                                                            placeholder="Rule 2"
                                                                            className="app-login-form-field"
                                                                            {...register("annexure_2")}
                                                                        />
                                                                        {/* {errors?.last_name && <span className="app-warning-content">{errors?.last_name?.message}</span>} */}
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} lg={6}>
                                                                        <input
                                                                            type={"text"}
                                                                            placeholder="Rule 3"
                                                                            className="app-login-form-field"
                                                                            {...register("annexure_3")}
                                                                        />
                                                                        {/* {errors?.first_name && <span className="app-warning-content">{errors?.first_name?.message}</span>} */}
                                                                    </Grid>

                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </li>
                                </ul>}

                               {!tenantAdded && <Grid container>
                                    <Grid item xs={12}>
                                        <div className="app-auth-button justify-content-center">
                                            <Button
                                                variant="contained"
                                                type='submit'

                                            >
                                                {loading ? <CircularProgress size={25} color='inherit' /> : 'Create Rental Agreement'}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>}

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
